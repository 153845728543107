/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import './index.css'

export default function Blogs() {
    return (
        <div className='blogs' style={{ overflow: 'hidden' }}>
            <section style={{ backgroundColor: "#f7f7f7" }} className='section1 mt-5'>
                <div className='container-fluid container-xl'>
                    <div className='sectionTitle'>
                        <h2 className='section_title p-4'>Dégustez la tapenade, une symphonie de saveurs d’olives, en une bouchée exquise!</h2>
                    </div>
                    <div className='sectionContent py-3'>
                        <div className='row'>
                            <div className='col-12 col-md-7 col-lg-8' data-aos="fade-right">
                                <div className='text_And_img'>
                                    <p>Régalez-vous avec notre tapenade d’olive maison, offrant une saveur riche et authentique, sans compromis sur la santé. Notre recette simple, utilisant le moulin à épices DAMAY, marie olives noires, huile d’olive, ail (optionnel) et sel pour une texture fine et homogène. Parfaite pour accompagner vos plats préférés, cette alternative saine aux tartinades commerciales séduit par sa fraîcheur et son goût. Essayez-la dès maintenant et partagez ce délice avec vos proches !</p>
                                    <img className='my-3' src={'/images/blogs/text_Image1-768x225.png'} alt='olive' />
                                </div>
                            </div>
                            <div className='col-12 col-md-5 col-lg-4' data-aos="fade-left">
                                <div className='imgGif'>
                                    <a target="_blank" href='https://www.youtube.com/shorts/jTJIt-9s0Mo'>
                                        <img src={'/images/blogs/gif1.gif'} alt='gif olive' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{ backgroundColor: "#fff" }} className='section2 mt-5'>
                <div className='container-fluid container-xl'>
                    <div className='sectionTitle'>
                        <h2 className='section_title p-4'>Sublimez vos plats avec notre Curcuma en poudre, L’or des épices!</h2>
                    </div>
                    <div className='sectionContent py-3'>
                        <div className='row'>
                            <div className='col-12 col-md-5 col-lg-4' data-aos="fade-right" >
                                <div className='imgGif'>
                                    <a target="_blank" href='https://www.youtube.com/shorts/B1tFNTrqPEc' >
                                        <img src={'/images/blogs/gif2.gif'} alt='gif curcuma' />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-md-7 col-lg-8' data-aos="fade-left">
                                <div className='text_And_img '>
                                    <p>
                                        Découvrez la magie du curcuma frais moulu chez vous pour des plats irrésistibles! Avec notre broyeur à épices innovant, obtenez une poudre de qualité supérieure en un instant. Donnez vie à vos recettes avec les bienfaits santé de cet ingrédient antioxydant. Transformez vos repas en véritables expériences culinaires et partagez vos résultats avec nous sur Instagram et Facebook. Prêt à relever le défi?
                                    </p>
                                    <img className='my-3' src={'/images/blogs/text_Image2.png'} alt='curcuma' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{ backgroundColor: "#f7f7f7" }} className='section3 mt-5' data-aos="flip-left" >
                <div className='container-fluid container-xl'>
                    <div className='sectionTitle'>
                        <h2 className='section_title p-4'>Dégustez nos pancakes d’avoine, une délicieuse tentation pour vos sensations gustatives!</h2>
                    </div>
                    <div className='sectionContent py-3'>
                        <div className='row'>
                            <div className='col-12 col-md-7 col-lg-8'>
                                <div className='text_And_img '>
                                    <p>Découvrez notre recette de pancakes saine et délicieuse, préparée en un clin d’œil avec notre broyeur à épices électrique DAMAY ! Des flocons d’avoine frais moulus, associés à des ingrédients simples comme le lait, le sucre, un œuf et des bananes, offrent des pancakes moelleux et nutritifs. Savourez chaque bouchée de cette option santé, garantie sans compromis sur la saveur. Prêt à vous régaler ?</p>
                                    <img className='my-3' src={'/images/blogs/text_Image3.png'} alt='pancakes' />
                                </div>
                            </div>
                            <div className='col-12 col-md-5 col-lg-4'>
                                <div className='imgGif'>
                                    <a href="https://www.youtube.com/shorts/HjDcAJDAOmw" target="_blank">
                                        <img src={'/images/blogs/gif3.gif'} alt='gif pancakes' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{ backgroundColor: "#fff" }} className='section4 mt-5' data-aos="zoom-in">
                <div className='container-fluid container-xl'>
                    <div className='sectionTitle'>
                        <h2 className='section_title p-4'>Gingembre Frais: Le secret de vos plats épicés!</h2>
                    </div>
                    <div className='sectionContent py-3'>
                        <div className='row'>
                            <div className='col-12 col-md-5 col-lg-4'>
                                <div className='imgGif'>
                                    <a href="https://www.youtube.com/shorts/QN3ioFVlm84" target="_blank">
                                        <img src={'/images/blogs/gif4.gif'} alt='gif Gingembre' />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-md-7 col-lg-8'>
                                <div className='text_And_img '>
                                    <p>
                                        Finis les compromis sur la fraîcheur du gingembre avec notre broyeur de haute qualité DAMAY. Découvrez le goût intense et les bienfaits pour la santé de ce superaliment, moulu à la maison en un clin d’œil. Soulagez les douleurs, stimulez la digestion et renforcez votre immunité avec du gingembre frais à portée de main. Prêt à transformer votre cuisine en un paradis épicé?
                                    </p>
                                    <img className='my-3' src={'/images/blogs/text_Image4.png'} alt='Gingembre' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ backgroundColor: "#f7f7f7" }} className='section5 mt-5' data-aos="zoom-out">
                <div className='container-fluid container-xl'>
                    <div className='sectionTitle'>
                        <h2 className='section_title p-4'>Plongez dans la tentation croquante du beurre de cacahuète!</h2>
                    </div>
                    <div className='sectionContent py-3'>
                        <div className='row'>
                            <div className='col-12 col-md-7 col-lg-8'>
                                <div className='text_And_img '>
                                    <p>
                                        Explorez la délicieuse aventure du beurre d’arachide fait maison avec le broyeur à épices DAMAY. Grillez légèrement les arachides pour libérer leur saveur naturelle, puis transformez-les en une pâte crémeuse. Ajoutez une touche sucrée de miel ou de sirop d’érable, ou une pincée de sel pour une version salée. Avec un contrôle total sur les ingrédients, vous obtiendrez une satisfaction incomparable à chaque tartine ou ajout à vos recettes préférées!
                                    </p>
                                    <img className='my-3' src={'/images/blogs/text_Image5.png'} alt='cacahuète' />
                                </div>
                            </div>
                            <div className='col-12 col-md-5 col-lg-4'>
                                <div className='imgGif'>
                                    <a href="https://www.youtube.com/shorts/FF0_z3IGdL4" target="_blank">
                                        <img src={'/images/blogs/gif5.gif'} alt='gif cacahuète' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{ backgroundColor: "#fff" }} className='section6 mt-5' data-aos="fade-up">
                <div className='container-fluid container-xl'>
                    <div className='sectionTitle'>
                        <h2 className='section_title p-4'>Délice Fondant: Notre chocolat à tartiner vous fera Craquer!</h2>
                    </div>
                    <div className='sectionContent py-3'>
                        <div className='row'>
                            <div className='col-12 col-md-5 col-lg-4'>
                                <div className='imgGif'>
                                    <a target="_blank" href="https://www.youtube.com/shorts/D8FCEX6UZYg">
                                        <img src={'/images/blogs/gif6.gif'} alt='gif chocolat' />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-md-7 col-lg-8'>
                                <div className='text_And_img '>
                                    <p>
                                        Découvrez notre recette exclusive de chocolat à tartiner maison aux saveurs orientales, un délice irrésistible à partager en famille. Avec des ingrédients simples et sains, notre mélange gourmand de biscuit, sucre, huile d’olive, cacao et cacahuètes, préparé avec le moulin à café DAMAY, offre une explosion de saveurs. Contrôlez les éléments nutritionnels en concoctant votre propre pâte à tartiner, parfaite sur du pain, des crêpes ou des gaufres. Variez les plaisirs en utilisant d’autres fruits à coque. Essayez cette recette facile et délicieuse dès aujourd’hui et partagez vos retours avec nous !
                                    </p>
                                    <img className='my-3' src={'/images/blogs/text_Image6.png'} alt='chocolat' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{ backgroundColor: "#f7f7f7" }} className='section7 mt-5' data-aos="fade-zoom-in">
                <div className='container-fluid container-xl'>
                    <div className='sectionTitle'>
                        <h2 className='section_title p-4'>Plongez dans l’excellence avec l’Amlou Marocain: Une Pâte Délicieuse et Riche en Secrets!

                        </h2>
                    </div>
                    <div className='sectionContent py-3'>
                        <div className='row'>
                            <div className='col-12 col-md-7 col-lg-8'>
                                <div className='text_And_img '>
                                    <p>
                                        Préparez votre propre beurre d’amande maison avec facilité grâce au robot broyeur DAMAY, pour une saveur délicieuse et une nutrition optimale. Mélangez simplement des amandes, de l’huile d’olive et du miel dans notre broyeur jusqu’à obtenir une texture lisse et crémeuse. Servez ce délice sur du pain, des crêpes ou des fruits pour une alternative saine et savoureuse. Essayez cette recette dès aujourd’hui et partagez vos impressions avec nous!
                                    </p>
                                    <img className='my-3' src={'/images/blogs/text_Image7.png'} alt='l’Amlou' />
                                </div>
                            </div>
                            <div className='col-12 col-md-5 col-lg-4'>
                                <div className='imgGif'>
                                    <img src={'/images/blogs/gif7.gif'} alt='gif l’Amlou' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  robots: [],
  piecesRechange: [],
  cart: JSON.parse(localStorage.getItem('cart')) || [],
}

export const Products = createSlice({
  name: 'Products',
  initialState: initialState,
  reducers: {
    robots: (state, action) => {
      state.robots = action.payload.data
    },

    PiecesRechange: (state, action) => {
      state.piecesRechange = action.payload.data
    },

    addToCart: (state, action) => {
      const item = action.payload;
      const existingItem = state.cart.find(cartItem =>
        cartItem.id === item.id && cartItem.category === item.category
      );

      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        state.cart.push(item);
      }
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },

    increaseQuantity: (state, action) => {
      const { id, category } = action.payload;
      const item = state.cart.find(cartItem =>
        cartItem.id === id && cartItem.category === category
      );

      if (item) {
        item.quantity += 1;
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },

    decreaseQuantity: (state, action) => {
      const { id, category } = action.payload;
      const item = state.cart.find(cartItem =>
        cartItem.id === id && cartItem.category === category
      );

      if (item && item.quantity > 1) {
        item.quantity -= 1;
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },

    removeFromCart: (state, action) => {
      const { id, category } = action.payload;
      state.cart = state.cart.filter(cartItem =>
        !(cartItem.id === id && cartItem.category === category)
      );
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },

    removeAllFromCart: (state, action) => {
      state.cart = [];
      localStorage.setItem('cart', JSON.stringify(state.cart));
    }
  }
});



export const { robots, PiecesRechange, addToCart,removeFromCart,increaseQuantity,decreaseQuantity,removeAllFromCart } = Products.actions;
export default Products.reducer;

export const selectCartSubtotal = (state) => {
  return state.Products.cart.reduce((subtotal, item) => {
    return subtotal + (item.quantity * item.price);
  }, 0);
};

import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


export default function CircularIndeterminate() {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', position: 'fixed',left:0,top:0, backgroundColor: '#fff', zIndex: 999 }}>
            <div>
                <CircularProgress />
            </div>
        </div>
    );
}
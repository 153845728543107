import  { useState } from 'react'
import { Link, Outlet,useNavigate } from 'react-router-dom';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useLocation } from 'react-router-dom';
import { useGetuserDataQuery } from '../../store/ApiSlice';
import { useSelector,useDispatch } from 'react-redux';
import './index.css'
import CircularIndeterminate from '../../components/loading/Loading';
import { logoutUser } from '../../store/Auth';
import { logout } from '../../postApi/Axios';

export default function Index() {
    const userToken = useSelector(state=>state.Auth.userToken);
    const dispatch=useDispatch();
    const navigate = useNavigate()
    const {data:userdata,isLoading:isloadinguserdata,refetch}=useGetuserDataQuery(userToken)
    const [loading,setloading]=useState(false);
    const location=useLocation()
    const currentUrl = location.pathname;
    const [selectedPage, setSelectedPage] = useState(currentUrl);
    const handlePageClick = (page) => {
        setSelectedPage(page);
    };

    if(isloadinguserdata || loading){
        return(
            <div>
                <CircularIndeterminate />
            </div>
        )
    }




    const handleLogout = async () => {
        try {
            setloading(true);
            const response = await logout(userToken);
            if (response.status === 200) {
                dispatch(logoutUser());
                navigate('/login');
            } else {
                throw new Error('Logout failed. Please try again.');
            }
        } catch (error) {
            alert('Logout failed. ' + (error.message || 'Please try again.'));
        } finally {
            setloading(false);
        }
    };

    return (
        <div className='userPagesIndex my-3'>
            <div className='container-fluid'>
                <div className='row g-5'>
                    <div className='col-12 col-md-4 col-xl-3 '>
                        <div className='' style={{ width: '100%' }}>
                            <div className='d-flex align-items-end mb-4'>
                                <AccountCircleRoundedIcon sx={{ fontSize: 100, color: '#b5b4b4' }} />
                                <h4 style={{ fontWeight: '300', color: '#a2a3a5' }}>Bonjour {userdata.nom} !</h4>
                            </div>
                            <div className='pagesList'>
                            <ul className='p-0'>
                            <li>
                                <Link
                                    to={'/user'}
                                    className={selectedPage === '/user' ? 'selectedPage' : ''}
                                    onClick={() => handlePageClick('/user')}
                                >
                                    Données personnelles
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/user/commandes'}
                                    className={selectedPage === '/user/commandes' ? 'selectedPage' : ''}
                                    onClick={() => handlePageClick('/user/commandes')}
                                >
                                    Commandes
                                </Link>
                            </li>
                            <li>
                                <Link
                                to={'/user/addresses'}
                                    className={selectedPage === '/user/addresses' ? 'selectedPage' : ''}
                                    onClick={() => handlePageClick('/user/addresses')}
                                >
                                    Adresses
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to=''
                                    className={selectedPage === 'logout' ? 'selectedPage' : ''}
                                    onClick={()=> handleLogout()}
                                >
                                    Se déconnecter
                                </Link>
                            </li>
                        </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-8 col-xl-9 '>
                        <div className='mt-0 mt-md-5' style={{ width: '100%' }}>
                            <Outlet context={{ userdata,refetch,userToken }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// @ts-nocheck
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkValidityToken } from '../postApi/Axios';
import CircularIndeterminate from '../components/loading/Loading';
import { logoutUser } from '../store/Auth';

export default function PrivateRoute({ children }) {
    const userToken = useSelector((state) => state.Auth.userToken);
    const dispatch = useDispatch();
    const [isValidToken, setIsValidToken] = useState(null);

    useEffect(() => {
        const checkToken = async () => {
            if (userToken) {
                try {
                    const response = await checkValidityToken(userToken);
                    if (response.status === 200) {
                        setIsValidToken(true);
                    } else {
                        setIsValidToken(false);
                        dispatch(logoutUser());
                    }
                } catch (error) {
                    console.error('An error occurred while checking token validity:', error);
                    setIsValidToken(false);
                    dispatch(logoutUser());
                }
            } else {
                setIsValidToken(false);
            }
        };
        checkToken();
    }, [userToken, dispatch]);

    if (isValidToken === null) {
        // Render loading spinner or nothing while token validation is in progress
        return (
            <div>
                <CircularIndeterminate />
            </div>
        );
    }

    if (!isValidToken) {
        // Redirect to the login page
        return <Navigate to="/login" />;
    }

    return children;
}

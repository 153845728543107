import React, { useState } from 'react'
import './index.css'

export default function GuideUtilisation() {
    const [showVideo1, setShowVideo1] = useState(false);
    const [showVideo2, setShowVideo2] = useState(false);
    const [showVideo3, setShowVideo3] = useState(false);
    const [showVideo4, setShowVideo4] = useState(false);

    return (
        <div className='GuideUtilisation pt-5'>
            <section style={{ backgroundColor: '#f7f7f7' }} className='section1'>
                <div className='container-fluid container-xl'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div style={{ marginRight: '100px' }} className='iconsleft d-none d-xl-block'>
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                        </div>
                        <div style={{ width: '100%' }} className='GuideUtilisationContent m-3'>
                            <h2 className='section_title py-4'>{`Verrouillage du broyeur d'épices Damay`}</h2>
                            <ul className='p-0' >
                                <li>
                                    <p>Veuillez placer le couvercle du broyeur sur l’ouverture du robot broyeur.</p>
                                </li>
                                <li>
                                    <p>Ensuite, insérez les vis de serrage dans les interrupteurs de verrouillage situés des deux côtés du broyeur.</p>
                                </li>
                                <li>
                                    <p>Finalement, pressez les oreillettes de serrage jusqu’à ce qu’elles s’enclenchent sur le couvercle du broyeur grâce à leur mécanisme rotatif</p>
                                </li>
                            </ul>
                            <div className='videoytb'>
                                {!showVideo1 ? (
                                    <img
                                        src={'/images/guide/coverVd1.jpg'}
                                        alt="Cover 1"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => setShowVideo1(true)}
                                    />
                                ) : (
                                    <iframe style={{ width: "100%", aspectRatio: 1, maxHeight: '600px' }} src="https://www.youtube.com/embed/1waqu2uAnMg?si=0CBYflklQpbxKfQE&amp;controls=0&amp;rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)}
                            </div>
                        </div>
                        <div style={{ marginLeft: '100px' }} className='iconsright d-none d-xl-block'>
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                        </div>
                    </div>
                </div>
            </section>

            {/*section2*/}
            <section style={{ backgroundColor: '#fff' }} className='section2'>
                <div className='container-fluid container-xl'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div style={{ marginRight: '100px', visibility: 'hidden' }} className='iconsleft d-none d-xl-block'>
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                        </div>
                        <div style={{ width: '100%' }} className='GuideUtilisationContent m-3'>
                            <h2 className='section_title py-4'>Durée de broyage</h2>
                            <ul className='p-0' >
                                <li>
                                    <p>Notre moulin à café est très puissant ce qui permet de moudre rapidement en seulement 30 secondes. Cela est très pratique pour économiser du temps lors de la préparation des aliments.</p>
                                </li>
                                <li>
                                    <p>Tout d’abord, veillez ne pas dépassez la durée de broyage recommandée..</p>
                                </li>
                                <li>
                                    <p>Et puis, assurez-vous de respecter les limites de capacité du broyeur. Consultez le manuel d’utilisation du broyeur pour connaître la quantité maximale d’aliments que vous pouvez y mettre à la fois. Le dépassement de cette limite peut entraîner des problèmes de fonctionnement ou même endommager le broyeur.</p>
                                </li>
                                <li>
                                    <p>En dernières étape, faites régulièrement l’entretien du broyeur sans utiliser de l’eau il suffit . Cela peut inclure le nettoyage régulier des lames et l’élimination des résidus accumulés.</p>
                                </li>
                            </ul>
                            <div className='videoytb'>
                                {!showVideo2 ? (
                                    <img
                                        src={'/images/guide/coverVd2.jpg'}
                                        alt="Cover 2"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => setShowVideo2(true)}
                                    />
                                ) : (
                                    <iframe style={{ width: "100%", aspectRatio: 1, maxHeight: '600px' }} src="https://www.youtube.com/embed/e-8G9z4UIAs?si=VJvyuRSBxk5be7tZ&amp;controls=0&amp;rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)}
                            </div>
                        </div>
                        <div style={{ marginLeft: '100px', visibility: 'hidden' }} className='iconsright d-none d-xl-block'>
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                        </div>
                    </div>
                </div>
            </section>

            {/*section3*/}
            <section style={{ backgroundColor: '#f7f7f7' }} className='section1'>
                <div className='container-fluid container-xl'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div style={{ marginRight: '100px' }} className='iconsleft d-none d-xl-block'>
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                        </div>
                        <div style={{ width: '100%' }} className='GuideUtilisationContent m-3'>
                            <h2 className='section_title py-4'>Balais electrique (charbon)</h2>
                            <ul className='p-0'>
                                <li>
                                    <p>Pour des raisons de sécurité, assurez-vous que le broyeur est hors tension et débranché.</p>
                                </li>
                                <li>
                                    <p>Les charbons sont situés près du moteur, de chaque côté.</p>
                                </li>
                                <li>
                                    <p>Une fois les charbons dégagés, examinez leur positionnement et la méthode de fixation, puis retirez les charbons usés en les retirant délicatement de leur emplacement.</p>
                                </li>
                                <li>
                                    <p>Mettez les nouveaux charbons fournis avec votre broyeur Damay dans les logements en s’ajustent correctement.</p>
                                </li>
                                <li>
                                    <p>Remplacer le vis et les caches que vous avez retirés précédemment, en vous assurant de leur installation correcte.</p>
                                </li>
                                <li>
                                    <p>Une fois tous remis en place, reconnectez le broyeur et effectuez un test pour vérifier le bon fonctionnement des nouveaux charbons.</p>
                                </li>
                            </ul>
                            <div className='videoytb'>
                                {!showVideo3 ? (
                                    <img
                                        src={'/images/guide/coverVd3.jpg'}
                                        alt="Cover 3"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => setShowVideo3(true)}
                                    />
                                ) : (
                                    <iframe style={{ width: "100%", aspectRatio: 1, maxHeight: '600px' }} src="https://www.youtube.com/embed/J4lFKgmrOb0?si=d2EpkRtQ2PB2FZK-&amp;controls=0&amp;rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)}
                            </div>
                        </div>
                        <div style={{ marginLeft: '100px' }} className='iconsright d-none d-xl-block'>
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                        </div>
                    </div>
                </div>
            </section>

            {/*section4*/}
            <section style={{ backgroundColor: '#fff' }} className='section2'>
                <div className='container-fluid container-xl'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div style={{ marginRight: '100px', visibility: 'hidden' }} className='iconsleft d-none d-xl-block'>
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                        </div>
                        <div style={{ width: '100%' }} className='GuideUtilisationContent m-3'>
                            <h2 className='section_title py-4'>Changement des lames</h2>
                            <ul className='p-0' >
                                <li>
                                    <p>
                                        Avant de commencer le processus de remplacement des lames, assurez-vous que l’appareil est entièrement éteint et déconnecté de la source d’alimentation.
                                    </p>
                                </li>
                                <li>
                                    <p>Pour desserrer les fixations qui maintiennent les lames en place sur les grands broyeurs Damay, utilisez une clé appropriée ou celle fournie avec l’appareil. Retirez ensuite les anciennes lames avec précaution.</p>
                                </li>
                                <li>
                                    <p>Mettez les nouvelles lames en place dans le même ordre et position que les anciennes. Assurez-vous de bien les fixer et à les sécurisés en resserrant les fixations.</p>
                                </li>
                                <li>
                                    <p>Avant de réutiliser le broyeur, vérifiez que les lames sont solidement fixées. Connectez l’appareil et effectuez un test en broyant une petite quantité d’aliments pour confirmer le bon fonctionnement des nouvelles lames.</p>
                                </li>
                            </ul>
                            <div className='videoytb'>
                                {!showVideo4 ? (
                                    <img
                                        src={'/images/guide/coverVd4.jpg'}
                                        alt="Cover 2"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => setShowVideo4(true)}
                                    />
                                ) : (
                                    <iframe style={{ width: "100%", aspectRatio: 1, maxHeight: '600px' }} src="https://www.youtube.com/embed/eTat0ET31LM?si=_HFuwOYzP2d_k8h_&amp;controls=0&amp;rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)}
                            </div>
                        </div>
                        <div style={{ marginLeft: '100px', visibility: 'hidden' }} className='iconsright d-none d-xl-block'>
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid1.gif'} alt='reglage' />
                            <img src={'/images/guide/gif_guid2.gif'} alt='reglage' />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

import React from 'react';
import './index.css';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector } from 'react-redux';

export default function PiecesRechange() {
  const PiecesRechange = useSelector(state => state.Products.piecesRechange);

  if (!PiecesRechange) {
    return (
      <p>Loading...</p>
    );
  }

  const getMinPrice = (types) => Math.min(...types.map(type => type.price));
  const getMaxPrice = (types) => Math.max(...types.map(type => type.price));

  return (
    <div className='PiecesRechangePage'>
      <div className='container-fluid container'>
        <h2 className='section_title'>Pieces de rechange</h2>
        <div className='row'>
          {PiecesRechange.map(item => (
            <div className='col-6 col-md-4 col-lg-3 col-xxl-2 my-3' key={item.id}>
              <div className='position-relative p-2'>
                <div style={{ borderRadius: 0, padding: 0 }} className='card-image justify-content-center d-flex position-relative'>
                  <img style={{ width: '100%', aspectRatio: 1 }} src={item.types.length > 0 & item.types[0].images.length>0  && item.types[0].images[0].image_url} alt='related product' />
                </div>
                <div className=''>
                  <div className='productName d-flex justify-content-center align-items-center'>
                    <p className='m-0'>{item.name}</p>
                    <ArrowForwardIcon style={{ color: '#919191' }} />
                  </div>
                  <div className='addToCart_price d-flex justify-content-around align-items-center'>
                    <h4 className='m-0'>
                      {getMinPrice(item.types)} - {getMaxPrice(item.types)} Dhs
                    </h4>
                  </div>
                </div>
                <Link className='linkToProductPage' to={`/Product/Pieces-rechange/${item.slug}`}></Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Badge from '@mui/material/Badge';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './index.css';
import { logoutAdmin } from '../../../postApi/Axios';
import { useSelector,useDispatch } from 'react-redux';
import CircularIndeterminate from '../../../components/loading/Loading';
import { useNavigate } from 'react-router-dom';
import { logout_Admin } from '../../../store/Auth';


export default function Nav() {
    const adminToken = useSelector(state => state.Auth.adminToken);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading,setLoading]=useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubmitLogout = async() =>{
        try {
            setLoading(true);
                await logoutAdmin(adminToken);
                dispatch(logout_Admin());
                navigate('/admin');
        } catch (error) {
            dispatch(logout_Admin());
            navigate('/admin');
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
        {loading && <CircularIndeterminate />}
        <div className='py-2 bg-dark'>
            <div className='container-fluid'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='start_content d-flex align-items-center'>
                        <IconButton data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                            <MenuOutlinedIcon sx={{ color: "#fff", fontSize: 30 }} />
                        </IconButton>

                        <div>
                            <img src={'/03-PNG/07-Logo DAMAY Rouge VF.png'} style={{ height: '30px' }} alt='logo' />
                        </div>
                    </div>
                    
                    <div className='last_content d-flex align-items-center '>
                        
                        <div>
                            <IconButton>
                                <Badge badgeContent={17} color="error">
                                    <NotificationsIcon sx={{ color: '#fff', fontSize: 30 }} />
                                </Badge>
                            </IconButton>
                        </div>
                        <div>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle sx={{ color: '#fff', fontSize: 30 }} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem style={{ fontSize:'20px',fontWeight:600,padding:'5px 27px', }} >Paramètres</MenuItem>
                                <MenuItem style={{ fontSize:'20px',fontWeight:600,padding:'5px 27px', }} onClick={handleSubmitLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Departement from './Departement/Departement';
import { Link } from 'react-router-dom';
import OnhoverPanier from './sidePanier/OnhoverPanier';
import OnhoverUser from './sidePanier/OnhoverUser';
import './index.css';

export default function LargeNavbar({ robots, PiecesRechange, cartlenght }) {

    const [showDepartement, setShowDepartement] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [userisHovered, setuserIsHovered] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    const handleSearchChange = (event) => {
        const value = event.target.value.trim(); // Trim the input to remove leading and trailing spaces
        setSearchTerm(value);
        if (value.length > 0) { // Only proceed if the input is not just spaces
            if (robots && robots.length > 0) {
                const filteredRobots = robots.filter(robot => robot.name.toLowerCase().includes(value.toLowerCase()));
                setFilteredResults([...filteredRobots]);
            } else {
                setFilteredResults([]);
            }
        } else {
            setFilteredResults([]); // Clear results if input is empty
        }
    };

    const handleSearchSelect = () => {
        setSearchTerm(''); // Clear the input
        setFilteredResults([]); // Clear the results
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMouseEnterUser = () => {
        setuserIsHovered(true);
    };

    const handleMouseLeaveUser = () => {
        setuserIsHovered(false);
    };

    const handleMouseEnterdepartement = () => {
        setShowDepartement(true);
    };

    const handleMouseLeavedepartement = () => {
        setShowDepartement(false);
    };

    return (
        <div className="container-fluid position-relative">
            <div className='row justify-content-center' style={{ height: '80px' }}>
                <div className='logo col-2'>
                    <Link onClick={() => setShowDepartement(false)} to={'/'} className="logo">
                        <img src={'/03-PNG/03-Logo DAMAY Blanc VF.png'} style={{ width: '80%', height: '100%' }} alt='logo' />
                    </Link>
                </div>

                <div className='departement col-2 justify-content-center'
                    onMouseEnter={handleMouseEnterdepartement}
                    onMouseLeave={handleMouseLeavedepartement}
                >
                    <IconButton className='p-4' style={{ borderRadius: '0px', width: '100%' }}
                    >
                        <WidgetsIcon sx={{ color: '#fff', fontSize: 25 }} />
                        <a className='' href='#'>Menu</a>
                    </IconButton>
                </div>

                <div className='search position-relative col-4'>
                    <input
                        type='text'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleSearchSelect();
                            }
                        }}
                    />
                    <IconButton className='searchIcon'><SearchIcon /></IconButton>
                    {filteredResults.length > 0 && searchTerm.length > 0 && (
                        <div className="search-dropdown">
                            {filteredResults.map((item, index) => (
                                <div key={index} className="search-item">
                                    <IconButton
                                        component={Link}
                                        to={`/product/${item.slug}`}
                                        style={{ fontSize: '18px', fontWeight: 600, color: 'black', borderRadius: 0 }}
                                        onClick={handleSearchSelect} // Add this line
                                    >
                                        {item.name}
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="shorts position-relative col-3 d-flex justify-content-evenly">
                    <div
                        className="position-relative d-flex align-items-center"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ height: '100%', width: '50%' }}>
                        <IconButton style={{ width: '100%' }} className=''>
                            <Link className='position-relative' to={'/panier'}>
                                <ShoppingCartOutlinedIcon sx={{ color: '#fff', fontSize: 30 }} />
                                {cartlenght ? (
                                    <div className='point'></div>
                                ) : ''}
                            </Link>
                        </IconButton>
                        {isHovered && (
                            <div className='panierpopup'>
                                <OnhoverPanier />
                            </div>
                        )}
                    </div>
                    <div
                        className="position-relative d-flex align-items-center justify-content-center"
                        onMouseEnter={handleMouseEnterUser}
                        onMouseLeave={handleMouseLeaveUser}
                        style={{ height: '100%', width: '50%', backgroundColor: '#000000' }}>
                        <IconButton component={Link} to={'/login'}>
                            <PermIdentityOutlinedIcon sx={{ color: '#fff', fontSize: 30 }} />
                        </IconButton>
                        {userisHovered && (
                            <div className='userpopup'>
                                <OnhoverUser />
                            </div>
                        )}
                    </div>

                    <IconButton>
                        <LanguageOutlinedIcon sx={{ color: '#fff', fontSize: 30 }} />
                    </IconButton>
                </div>
            </div>
            {showDepartement && (
                <div
                    onMouseEnter={handleMouseEnterdepartement}
                    onMouseLeave={handleMouseLeavedepartement}
                >
                    <Departement PiecesRechange={PiecesRechange} robots={robots} setShowDepartement={setShowDepartement} />
                </div>
            )}
        </div>
    );
}

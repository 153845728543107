/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './index.css'
import { Link, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { addToCart } from '../../store/Products';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';



export default function ProductPiece() {
    const { productSlug } = useParams();
    const [capacity, setcapacity] = useState('');
    const [quantity, setquantity] = useState(1);
    const [price, setPrice] = useState(null)
    const [productId, setProductId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const dispatch = useDispatch();
    const [imageUrls, setImageUrls] = useState([]);
    const [mainImage, setMainimage] = useState()


    const piecesRechange = useSelector(state =>
        state.Products.piecesRechange.find(piecesRechange => piecesRechange.slug === productSlug)
    );


    const allProduct = useSelector(state => state.Products.robots)
    const getMinPrice = (variants) => Math.min(...variants.map(variant => variant.price));
    const getMaxPrice = (variants) => Math.max(...variants.map(variant => variant.price));


    const handleAddToCart = () => {
        dispatch(
            addToCart({
                id: productId,
                name: piecesRechange.name,
                capacity: capacity,
                price: price,
                quantity: quantity,
                imageUrl: piecesRechange.types[0].images[0].image_url,
                category: 'piece rechange'
            })
        );
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
        }, 3000); // Close popup after 3 seconds
    };

    const incraseQtt = () => {
        setquantity(quantity + 1)
    }

    const dicraseQtt = () => {
        if (quantity > 1) {
            setquantity(quantity - 1)
        }
    }

    const handleChange = (event) => {
        const selectedCapacity = event.target.value;
        setcapacity(selectedCapacity);
        if (selectedCapacity) {
            const selectedType = piecesRechange.types.find(type => type.capacity === selectedCapacity);
            if (selectedType) {
                setPrice(selectedType.price);
                setProductId(selectedType.id); // Assuming this is how you want to update the product ID
            }
        } else {
            setPrice(null);
            setProductId(null); // Reset the product ID if no capacity is selected
        }
    };

    useEffect(() => {
        if (capacity) {
            const selectedType = piecesRechange.types.find(type => type.capacity === capacity);
            if (selectedType) {
                setPrice(selectedType.price);
            }
        } else {
            setPrice(null);
        }
    }, [capacity]);

    useEffect(() => {
        if (piecesRechange) {
            const newImageUrls = piecesRechange.types[0].images.map(img => img.image_url);
            setImageUrls(newImageUrls);
            setMainimage(newImageUrls[0])
        } else {
            setImageUrls([]);

        }
    }, [productSlug, piecesRechange]);



    if (!piecesRechange) {
        return (
            <div className="Product">
                <div className="loading">
                    <p>loading</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{piecesRechange.name}</title>
                    <meta name="description" content={piecesRechange.description} />
                    <meta name="keywords" content={piecesRechange.types[0].Keywords} />
                </Helmet>
            </HelmetProvider>
            {
                showPopup && (
                    <Dialog
                        open={showPopup}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ fontSize: '18px' }} >
                            Le produit a été ajouté au panier
                        </Alert>
                    </Dialog>
                )
            }
            <div className='Product'>
                <div className='container-fluid container-xxl'>
                    <div className='section_one'>
                        <div className="image-container">
                            <div className='row'>
                                <div className='col-12 col-lg-6 d-flex'>
                                    <div className='d-flex align-items-start gap-3'>
                                        <div className='d-flex flex-column gap-3 rounded'>
                                            {imageUrls.slice(0, 3).map((item, index) => (
                                                <img onMouseEnter={() => setMainimage(item)} key={index} src={item} alt='product' style={{ width: '100px', height: '100px', cursor: 'pointer', border: '1px solid #d7d7d7' }} className='rounded' />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <img src={mainImage} alt='main product' style={{ width: '100%', aspectRatio: 1, maxWidth: '500px' }} />
                                    </div>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <div className='product_description mt-5 mt-lg-0'>
                                        <div className='product_name'>
                                            <h3>{piecesRechange.name}</h3>
                                        </div>

                                        <div className='product_price mb-3'>
                                            <p style={{ fontWeight: '600', color: '#707070' }}>{piecesRechange.description}</p>
                                        </div>

                                        <div style={{ maxWidth: '300px' }} className='product_capacity my-4'>
                                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: 600 }}>Capacite de machine:</p>
                                            <FormControl sx={{ minWidth: '90%', maxWidth: '300px' }}>
                                                <Select
                                                    value={capacity}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="">
                                                        <em>Choose an option</em>
                                                    </MenuItem>
                                                    {piecesRechange.types.map(item => (
                                                        <MenuItem value={item.capacity}>{item.capacity + 'g'}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className='product_price mb-3'>
                                            <h3>{price} dhs</h3>
                                        </div>

                                        <div className='qtt_addCart mt-4 d-flex align-items-center'>
                                            <div className='qtt me-2'>
                                                <button onClick={dicraseQtt} >
                                                    <RemoveCircleOutlineIcon style={{ color: '#fff', fontSize: '30px', fontWeight: 600 }} />
                                                </button>
                                                <span style={{ fontSize: '20px', fontWeight: 600, color: 'black', width: '20px', marginLeft: '3px', marginRight: '3px' }}>{quantity}</span>
                                                <button onClick={incraseQtt} >
                                                    <AddCircleOutlineIcon style={{ color: '#fff', fontSize: '30px', fontWeight: 600 }} />
                                                </button>
                                            </div>
                                            <div className='addCart'>
                                                <button
                                                    className={`btn_Addcart ${capacity === '' ? 'disabled' : ''}`}
                                                    disabled={capacity === ''}
                                                    onClick={handleAddToCart}
                                                >
                                                    Ajouter au panier
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='relatedProducts my-5'>
                    <div className='p-3 my-4' style={{ backgroundColor: '#e5e5e559' }}>
                        <h2 className='section_title my-4'>Produits connexes</h2>
                    </div>

                    <div className='relatedProductsContent'>
                        <div className='container-fluid container-lg'>
                            <div className='row'>
                                {allProduct.map(item => (
                                    <IconButton component={Link} to={`/Product/${item.slug}`} key={item.id} className='col-12 col-sm-6 col-md-3 my-3'>
                                        <div className='card'>
                                            <div className='card-image justify-content-center d-flex'>
                                                <img style={{ width: '85%', aspectRatio: 1 }} src={item.variants.length > 0 && item.variants[0].images.length > 0 && item.variants[0].images[0].image_url} alt='related product' />
                                            </div>
                                            <div className='card-body'>
                                                <div className='productName d-flex justify-content-center'>
                                                    <p className='m-0'>{item.name}</p>
                                                    <ArrowRightAltIcon sx={{ color: '#919191' }} />
                                                </div>
                                                <div className='relatedProductPrice'>
                                                    <p>{getMinPrice(item.variants)} - {getMaxPrice(item.variants)} Dhs</p>
                                                </div>
                                            </div>
                                        </div>
                                    </IconButton>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


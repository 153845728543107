import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { useGetSubscribersQuery } from '../../../store/ApiSlice';
import CircularIndeterminate from '../../../components/loading/Loading';

export default function Subscribers() {
    const adminToken = useSelector(state => state.Auth.adminToken)
    const { data: subscribersData, isLoading: loadingSubscribers } = useGetSubscribersQuery(adminToken);

    if (loadingSubscribers) {
        return  <CircularIndeterminate />; // Loading state
    }

    if (!subscribersData || subscribersData.length === 0) {
        return (
            <div className='d-flex justify-content-center align-items-center'>
            <p>No subscribers data available.</p>
            </div>
        ) // Handling no data state
    }

    const formatDate = (dateString) => {
        return new Intl.DateTimeFormat('en-US').format(new Date(dateString));
    }

    return (
        <div className='Subscribers'>
            <div className='container-fluid container-xl'>
                <div>
                <h2 className='section_title my-4'>Subscribers</h2>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead style={{ backgroundColor:'#c3c3c3' }}>
                                <TableRow>
                                    <TableCell style={{ fontSize:'22px',fontWeight:600,color:'#333333' }}>Nom</TableCell>
                                    <TableCell style={{ fontSize:'22px',fontWeight:600,color:'#333333' }}>Email</TableCell>
                                    <TableCell style={{ fontSize:'22px',fontWeight:600,color:'#333333' }}>Abonné à</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscribersData.subscribers.map((subscriber) => (
                                    <TableRow
                                        key={subscriber.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell style={{ fontSize:'20px',fontWeight:600,color:'#616161' }}>{subscriber.name}</TableCell>
                                        <TableCell style={{ fontSize:'20px',fontWeight:600,color:'#616161' }}>{subscriber.email}</TableCell>
                                        <TableCell style={{ fontSize:'20px',fontWeight:600,color:'#616161' }}>{formatDate(subscriber.created_at)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import { removeProduct, updateProductStatus, updateProductField } from '../../../postApi/Axios';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import CircularIndeterminate from '../../../components/loading/Loading';
import { toast } from 'react-toastify';  // Assuming you are using react-toastify for notifications
import DescriptionIcon from '@mui/icons-material/Description'; // Icon for PDF upload
import { Dialog, DialogContent } from '@mui/material';
import ManageImages from './ManageImages';
import { storeFileTechnique } from '../../../postApi/Axios';


export default function RobotsProducts({ products, refetchRobots }) {
  const adminToken = useSelector(state => state.Auth.adminToken);
  const [loading, setloading] = useState(false)
  const [open, setOpen] = useState(false); // To control the dialog visibility
  const [currentProductId, setCurrentProductId] = useState(null); // To store current product ID

  // Dialog open handler
  const handleOpenDialog = (id) => {
    setCurrentProductId(id);
    setOpen(true);
  };

  // Dialog close handler
  const handleCloseDialog = () => {
    setOpen(false);
  };


  const handleUploadPDF = async (id, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("variant_id", id);  // Assuming variant_id is required
    setloading(true);
    try {
      const response = await storeFileTechnique(adminToken,formData)
      if (response.status === 200) {
        toast.success('PDF uploaded successfully!');
        refetchRobots();
      } else {
        toast.error('Error uploading PDF');
      }
    } catch (error) {
      console.error('Upload PDF Error:', error);
      toast.error('Error uploading PDF');
    } finally {
      setloading(false);
    }
  }

  const handleStatusChange = async (id, currentStatus) => {
    const status = currentStatus === 'Active' ? 'Inactive' : 'Active';
    setloading(true);
    try {
      const response = await updateProductStatus(adminToken, 'robot', id, status);
      if (response.status === 200) {
        toast.success('Status updated successfully');
        refetchRobots(); // Optionally refetch data if needed
      } else {
        toast.error('Failed to update status');
      }
    } catch (error) {
      toast.error('Error updating status');
    } finally {
      setloading(false);
    }
  };




  const handleRemove = async (id) => {
    setloading(true); // Assume setLoading is a function that updates your component's loading state
    try {
      const response = await removeProduct(adminToken, 'robot', id); // Ensuring that 'removeRobots' is imported and correctly set up to call your API
      if (response.status === 200) {
        refetchRobots()
        toast.success('Product successfully removed'); // Display a success message
      } else {
        toast.error('Failed to remove the product'); // Display an error message
      }
    } catch (error) {
      console.error('Error removing product:', error);
      toast.error('Error removing product'); // Display an error message if an exception occurred during the API call
    } finally {
      setloading(false); // Ensure loading is set to false after the operation is complete
    }
  };

  const handleCommit = async (id, field, value) => {
    setloading(true);
    try {
      const response = await updateProductField(adminToken, 'robot', id, field, value);
      if (response.status === 200) {
        toast.success('Field updated successfully');
        refetchRobots();
      } else {
        toast.error('Failed to update field');
      }
    } catch (error) {
      toast.error('Error updating field');
    } finally {
      setloading(false);
    }
  };

  const processRowUpdate = (newRow, oldRow) => {
    const updatedField = Object.keys(newRow).find(key => newRow[key] !== oldRow[key]);
    handleCommit(newRow.id, updatedField, newRow[updatedField]);
    return newRow;
  };

  const columns = [
    { field: 'Image', headerName: 'Image', width: 100, renderCell: (params) => <img src={params.value} alt="Product" style={{ width: '100%', aspectRatio: 1, borderRadius: '50%' }} /> },
    { field: 'name', headerName: 'name', width: 280, editable: true },
    { field: 'Ref', headerName: 'Ref', width: 130, editable: true },
    { field: 'Type', headerName: 'Type', width: 120, editable: true },
    { field: 'price', headerName: 'Price', width: 90, editable: true },
    { field: 'Capacity', headerName: 'Capacity', width: 90, editable: true },
    { field: 'qtt', headerName: 'qtt', width: 90, editable: true },
    {
      field: 'Status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => (
        <Switch
          checked={params.value === 'Active'}
          onChange={() => handleStatusChange(params.id, params.value)}
          color="primary"
        />
      )
    },
    { field: 'Description', headerName: 'Description', width: 280, editable: true },
    { field: 'Keywords', headerName: 'Keywords', sortable: false, width: 200, editable: true },
    {
      field: 'actions',
      headerName: 'Remove',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleRemove(params.id)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </>
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: 'Add images',
      headerName: 'Add images',
      width: 110,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleOpenDialog(params.id)} color="primary">
            <AddAPhotoRoundedIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </>
      ),
      sortable: false,
      filterable: false,
    },


    {
      field: 'Add technical file',
      headerName: 'Add technical file',
      width: 110,
      renderCell: (params) => (
        <>
          <IconButton component="label" color="primary">
            <DescriptionIcon />
            <input type="file" style={{ display: 'none' }} onChange={(e) => handleUploadPDF(params.id, e.target.files[0])} accept=".pdf" />
          </IconButton>
        </>
      ),
      sortable: false,
      filterable: false,
    },



  ];


  return (
    <>
      {loading && (<CircularIndeterminate />)}
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => toast.error('Error updating field')}
          rows={products}
          rowHeight={90}
          columns={columns}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }}
          pageSizeOptions={[5, 10]}
          experimentalFeatures={{ newEditingApi: true }}
          sx={{
            '& .MuiDataGrid-cell': {
              fontSize: '16px',
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            }
          }}
        />
        <span className='manageimagespopup'>
          <Dialog open={open} onClose={handleCloseDialog}>
            <DialogContent  >
              {currentProductId && <ManageImages productId={currentProductId} type={'robots'} refetchdata={refetchRobots} />}
            </DialogContent>
          </Dialog>
        </span>
      </div>
    </>
  );
}

/* eslint-disable eqeqeq */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import './index.css';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import { useOutletContext } from 'react-router-dom';
import { addAddress, updateAdress,deleteAdress,setdefaultAdress} from '../../../postApi/Axios';
import CircularIndeterminate from '../../../components/loading/Loading';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const initialFormState = {
    addressId: null, // Add addressId to the initial form state
    name: '',
    adresse: '',
    ville: '',
    phone: '',
    email: '',
    default: false,
};

const villes = ['casablanca', 'marrakech', 'Rabat', 'Tanger', 'Agadir'];

export default function Adresses() {
    const [updateAddress, setUpdateAddress] = useState(false);
    const [formData, setFormData] = useState(initialFormState);
    const { userdata,refetch,userToken } = useOutletContext();
    const [errorsValidation, setErrorsValidation] = useState({});
    const [loading,setLoading]=useState(false);
    const [successMsg,setsuccessMsg]=useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (userdata) {
            setFormData({
                name: userdata.nom,
                phone: userdata.tel,
                email: userdata.email,
                adresse: '',
                ville: '',
                default: false,
            });
        }
    }, [userdata]);
    const validateFormData = (data) => {
        let validationErrors = {};
        if (!data.name) {
            validationErrors.name = "Nom est requis.";
        } else if (data.name.length > 255) {
            validationErrors.name = "Nom ne doit pas dépasser 255 caractères.";
        }
        if (!data.adresse) {
            validationErrors.adresse = "Adresse est requise.";
        }
        if (!data.ville) {
            validationErrors.ville = "Ville est requise.";
        }
        if (!data.phone) {
            validationErrors.phone = "Tel est requis.";
        } else if (!/^\d{10}$/.test(data.phone)) {
            validationErrors.phone = "Tel doit contenir exactement 10 chiffres.";
        }
        if (!data.email) {
            validationErrors.email = "Email est requis.";
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            validationErrors.email = "Email n'est pas valide.";
        }
    
        return validationErrors;
    };
    

    const handleEditClick = (address) => {
        setFormData({
            addressId: address.id,
            name: userdata.nom,
            adresse: address.adresse,
            ville: address.ville || '',
            phone: userdata.tel,
            email: userdata.email,
            default: address.default || false,
        });
        setUpdateAddress(true);
    };

    const handleAnnulerClick = () => {
        setFormData({
            name: userdata.nom,
            phone: userdata.tel,
            email: userdata.email,
            adresse: '',
            ville: '',
            default: false,
        });
        setUpdateAddress(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateFormData(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrorsValidation(validationErrors);
            return;
        }
        try {
            setLoading(true);
            let response;
            if (updateAddress) {
                response = await updateAdress(userToken, formData,formData.addressId);
            } else {
                response = await addAddress(userToken, formData);
            }
            if (response.status === 200) {
                refetch();
                setsuccessMsg(updateAddress ? 'Adresse mise à jour avec succès' : 'Adresse ajoutée avec succès');
                setOpen(true);
                setFormData(initialFormState);
                setErrorsValidation({});
                setUpdateAddress(false);
            }
        } catch (error) {
            console.error('Failed to update address', error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleDelete = async (addressId) => {
        try {
            setLoading(true);
            const response = await deleteAdress(userToken,addressId);
            if (response.status === 200) {
                refetch()
                setsuccessMsg('Adresse supprimée avec succès');
                setOpen(true);
            }
        } catch (error) {
            console.error('Failed to delete address', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSetDefault = async (addressId) => {
        try {
            setLoading(true);
            const response = await setdefaultAdress(userToken, addressId);
            if (response.status === 200) {
                refetch()
                setsuccessMsg('Adresse définie comme par défaut avec succès');
                setOpen(true);
                
            }
        } catch (error) {
            console.error('Failed to set default address', error);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
        {loading && <CircularIndeterminate />}
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} >
                <Alert severity="success">{successMsg}</Alert>
            </Snackbar>
            <div>
                <div className='Mes_adresses_livraison pt-4'>
                    <h2 className='section_title text-start my-3'>Mes adresses de livraison</h2>
                    <div className='p-3'>
                        <div className='adresses_saved my-4'>
                            <div className='row'>
                                {userdata && userdata.adresses.map((item) => (
                                    <div key={item.id} className='col-12 my-2 col-md-6'>
                                        <div className='adresses_saved_columns position-relative'>
                                            <i onClick={() => handleDelete(item.id)}><DeleteIcon sx={{ color: '#e63812', fontSize: 30 }} /></i>
                                            <div className='first_line d-flex align-items-center'>
                                                <div className='mb-0 me-2'>{userdata.nom} {userdata.prenom}</div>
                                                <span className='mb-0'>{userdata.tel}</span>
                                            </div>
                                            <div className='second_line my-2'>
                                                <span className='mb-2'>{userdata.email}</span>
                                            </div>
                                            <div className='third_line'>
                                                <p className='adresse_saved mb-0'>{item.adresse}</p>
                                            </div>
                                            <div className='fourth_line'>
                                                <button onClick={() => handleEditClick(item)}>Éditer</button>
                                                <button  onClick={() => handleSetDefault(item.id)}>Définir par défaut</button>
                                            </div>
                                            {item.default===1 && (
                                                <div className='is_default'>
                                                    <div className='p-1' style={{ backgroundColor: '#e63812', color: '#fff', fontWeight: '400' }}>
                                                        Par Défaut
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <form className='form-adress my-3' onSubmit={handleSubmit}>
                            <h2 className='section_title text-start my-3'>{updateAddress ? 'Modifier Adresse' : 'Ajouter une adresse'}</h2>
                            <input
                                type='text'
                                name='name'
                                placeholder='Nom *'
                                
                                disabled
                                value={formData.name}
                                onChange={handleChange}
                            />
                            {errorsValidation.name && <p className="text-danger">{errorsValidation.name}</p>}
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <input
                                        type='text'
                                        name='adresse'
                                        placeholder='adresse *'
                                        
                                        value={formData.adresse}
                                        onChange={handleChange}
                                    />
                                    {errorsValidation.adresse && <p className="text-danger">{errorsValidation.adresse}</p>}
                                </div>
                                <div className='col-12 col-md-6'>
                                    <select
                                        name='ville'
                                        className='form-select'
                                        
                                        value={formData.ville}
                                        onChange={handleChange}
                                    >
                                        <option value=''>Ville *</option>
                                        {villes.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    {errorsValidation.ville && <p className="text-danger">{errorsValidation.ville}</p>}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <input
                                        type='text'
                                        name='phone'
                                        placeholder='Tel *'
                                        disabled
                                        
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                    {errorsValidation.phone && <p className="text-danger">{errorsValidation.phone}</p>}
                                </div>
                                <div className='col-12 col-md-6'>
                                    <input
                                        type='email'
                                        name='email'
                                        disabled
                                        placeholder='Email *'
                                        
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errorsValidation.email && <p className="text-danger">{errorsValidation.email}</p>}
                                </div>
                            </div>
                            <div className='d-flex justify-content-start align-items-center'>
                                <Checkbox
                                    id='default_adresse'
                                    name='default'
                                    checked={formData.default}
                                    onChange={handleChange}
                                />
                                <label htmlFor='default_adresse'>Définir comme adresse par défaut</label>
                            </div>

                            <div className='d-md-flex justify-content-md-end '>
                                <button type='submit' className='send_adresse my-2'>
                                    {updateAddress ? 'Modifier' : 'Ajouter'}
                                </button>
                                <button onClick={handleAnnulerClick} type='button' className='annuler_adresse my-2'>
                                    {updateAddress ? 'Annuler modification' : 'Annuler'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, FormControlLabel, Checkbox, InputLabel, MenuItem, FormControl, Select, Button } from '@mui/material';
import { toast } from 'react-toastify';
import CircularIndeterminate from '../../../components/loading/Loading';

// Ensure this is imported or defined elsewhere in your project
import { updateCouponapi } from '../../../postApi/Axios';

export default function UpdateCoupon({ admintoken, open, handleClose, refetch, coupon }) {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        code: '',
        discount_type: '',
        discount_amount: '',
        active: false
    });

    useEffect(() => {
        if (coupon) {
            setFormData({
                code: coupon.code,
                discount_type: coupon.discount_type,
                discount_amount: coupon.discount_amount,
                active: coupon.active
            });
        }
    }, [coupon]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const response = await updateCouponapi(admintoken, coupon.id, formData);
            if (response.status === 200) {
                toast.success('Coupon updated successfully');
                refetch();
                handleClose();
            } else {
                toast.error('Failed to update coupon');
            }
        } catch (error) {
            toast.error(`Failed to update coupon: ${error.message || ''}`);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <CircularIndeterminate />;
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent>
                <form className='p-3' onSubmit={handleSubmit}>
                    <div className="form-group my-3">
                        <label htmlFor="code">Code</label>
                        <input
                            id="code"
                            name="code"
                            type="text"
                            value={formData.code}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group my-3">
                        <FormControl fullWidth>
                            <InputLabel id="discount-type-label">Type de réduction</InputLabel>
                            <Select
                                labelId="discount-type-label"
                                id="discount-type-select"
                                name="discount_type"
                                value={formData.discount_type}
                                onChange={handleChange}
                                label="Type de réduction"
                            >
                                <MenuItem value={'percentage'}>Pourcentage</MenuItem>
                                <MenuItem value={'fixed'}>Fixe</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="form-group my-3">
                        <label htmlFor="discountAmount">Montant de réduction</label>
                        <input
                            id="discountAmount"
                            name="discount_amount"
                            type="number"
                            value={formData.discount_amount}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group my-3">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="active"
                                    checked={formData.active}
                                    onChange={handleChange}
                                />
                            }
                            label="Actif"
                        />
                    </div>
                    <div className="form-group">
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button type="submit">Mettre à jour</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}

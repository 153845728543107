import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import './index.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { login } from '../../postApi/Axios';
import Loading from '../../components/loading/Loading'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/Auth';
export default function Login() {
    const userToken = useSelector(state => state.Auth.userToken)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        emailOrTel: '',
        password: '',
        rememberMe: false
    });
    const [errors, setErrors] = useState({});
    const [loading, setloading] = useState(false)
    const [message, setMessage] = useState('');


    if (userToken) {
        return <Navigate to="/" />;
    }


    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'rememberMe' ? checked : value
        });
        setErrors({ ...errors, [name]: '' }); // Clear validation errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate form data
        let errors = {};
        if (!formData.emailOrTel) {
            errors.emailOrTel = 'Email or Tel is required';
        } else if (!(/^\S+@\S+\.\S+$/.test(formData.emailOrTel) || /^\d{10}$/.test(formData.emailOrTel))) {
            errors.emailOrTel = 'Invalid email or phone number format';
        }
        if (!formData.password) {
            errors.password = 'Password is required';
        }
        if (Object.keys(errors).length === 0) {
            try {
                setloading(true)
                const response = await login(formData);
                if (response.status === 200) {
                    dispatch(loginUser(response.data))
                    setFormData({
                        emailOrTel: '',
                        password: '',
                        rememberMe: false
                    })
                    navigate('/') // Redirect to the desired page upon successful login
                } else {
                    // Handle login failure (show error message, etc.)
                    throw new Error('Login failed. Please check your credentials.');
                }
            } catch (error) {
                setMessage('échec de la connexion. Veuillez réessayer.');
                if (error.errors) {
                    setErrors(error.errors);
                }
            } finally {
                setloading(false)
            }
        } else {
            setErrors(errors);
        }
    };


    return (
        <>
            {loading && (
                <Loading />
            )}
            <div className='LoginPage my-4'>
                <div className='container-fluid container-xl'>
                    <div className='row'>
                        <div style={{ background: "#fff", boxShadow: '-2px 0px 7px 1px #d7d7d7' }} className='col-12 col-lg-5 d-flex justify-content-center align-items-center'>
                            <div className='formRegister' style={{ width: '80%' }}>
                                <h2 className="section_title text-start pt-4">Login</h2>
                                <form  onSubmit={handleSubmit}>
                                    <div className='row' style={{ width: '100%', margin: 'auto' }}>
                                        <div className='col-12 my-3 p-0'>
                                            <input
                                                type="text"
                                                name="emailOrTel"
                                                value={formData.emailOrTel}
                                                onChange={handleChange}
                                                className={`input-field ${errors.emailOrTel ? 'error' : ''}`}
                                                placeholder='Email or Tel'
                                            />
                                            {errors.emailOrTel && <div className="error-message">{errors.emailOrTel}</div>}
                                        </div>
                                        <div className='col-12 p-0'>
                                            <input
                                                type="password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                className={`input-field ${errors.password ? 'error' : ''}`}
                                                placeholder='Password'
                                            />
                                            {errors.password && <div className="error-message">{errors.password}</div>}
                                        </div>
                                        <div className='remember_me my-3'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="rememberMe"
                                                        checked={formData.rememberMe}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label='Remember me'
                                            />
                                        </div>
                                        {message && (
                                            <div className='col-12 my-4'>
                                                <p style={{ color: message.includes('successfully') ? 'green' : 'red' }}>{message}</p>
                                            </div>
                                        )}
                                        <div className='d-flex align-items-center justify-content-between mb-3 px-0'>
                                            <IconButton style={{ color: '#000000' }} component={Link} to={'/register'}>Create an account</IconButton>
                                            <span className='loginbutton d-flex align-items-end'>
                                                <IconButton type='submit'>Login</IconButton>
                                                <ArrowRightAltIcon sx={{ font: 40, color: '#000000' }} />
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div style={{ background: "#000000", height: '600px' }} className='col-12 col-lg-7 p-0 d-none d-lg-block'>
                            <div style={{ height: '100%' }} className='theSteps d-flex justify-content-center align-items-center'>
                                <img style={{ width: '100%' }} src={'/images/DAMAY-1536x512.png'} alt="Damay" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React from 'react'
import './index.css'
import LargeNavebar from './nav_large/LargeNavebar'
import SmallNavebar from './nav_small/SmallNavebar'
import Sidenav from './nav_small/Sidenav'
import { useSelector } from 'react-redux'

export default function Navebar() {
const robots=useSelector(state=>state.Products.robots)
const PiecesRechange=useSelector(state=>state.Products.piecesRechange)
const cartlenght = useSelector(state=>state.Products.cart).length

if(!PiecesRechange || !robots ){
    return(
        <p>loading</p>
    )
}


    return (
        <>
            <div className='LargeNavebar d-none d-lg-block'>
                <LargeNavebar robots={robots} cartlenght={cartlenght}/>
            </div>

            <div className='SmallNavebar d-block d-lg-none p-2'>
                <SmallNavebar robots={robots} cartlenght={cartlenght}/>
                <Sidenav robots={robots} PiecesRechange={PiecesRechange}  />
            </div>
        </>
    )
}

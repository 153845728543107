import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'
import { IconButton } from '@mui/material'
import { useSelector } from 'react-redux';
import { selectCartSubtotal } from '../../../../store/Products';


export default function OnhoverPanier() {
    const cartSubtotal = useSelector(selectCartSubtotal);
    const cart = useSelector(state=>state.Products.cart)
    return (
        <div className='OnhoverPanier'>
            <div className='PanierContainer'>
                <div className='row'>
                    <div className='col-6 pe-0'>
                        <div className='Articles'>
                            {cart.map(item => (
                                <ul key={item}>
                                    <li>
                                        <img style={{ width: '90px', aspectRatio: 1 }} src={item.imageUrl} alt={item.name} />
                                        <div>
                                            <Link style={{ color: '#0d6efd' }} to={'/'}>{item.name}</Link>
                                            <p style={{ fontSize: '20px', fontWeight: 600, color: '#9b9b9b' }}>{item.quantity} x {item.price} Dhs</p>
                                        </div>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='rightSideContent py-4'>
                                <p style={{ fontSize: '22px', fontWeight: 600, color: '#5b5b5b' }} className='m-0'>Sous-Total: {cartSubtotal} dh</p>
                                <IconButton  component={Link} to={'/panier'}  className='voirPanier my-3'>Voir Panier</IconButton>
                                <div className='my-3'>
                                    <img style={{ width:'100%',aspectRatio:1 }} src={'/03-PNG/3516854.jpg'} alt='animation'/>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

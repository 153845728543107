import { useLocation,Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeAllFromCart } from '../store/Products';


function PaymentSuccess() {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get('message');
    const error = queryParams.get('error');
    dispatch(removeAllFromCart())
    return (
        <div style={{ height:'100vh' }} className='d-flex justify-content-center align-items-center'>
        <div style={{ padding: '20px', textAlign: 'center',width:'80vw' }}>
            <h1 style={{ color:'#5a9b5a' }}>Paiement réussi!</h1>
            <p style={{ fontSize:'22px',fontWeight:600 }}>Merci pour votre paiement. Votre transaction a été complétée .</p>
            <Link to="/" style={{ textDecoration: 'none', color: 'blue',fontSize:'18px',fontWeight:600 }}>{`aller à la page d'accueil`}</Link>
        </div>
        </div>
    );
}

export default PaymentSuccess




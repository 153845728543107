import { configureStore } from "@reduxjs/toolkit";
import {RobotsApiSlice} from './RobotsApiSlice'
import { Products } from "./Products";
import { PiecesRechangeApiSlice } from "./PiecesRechangeApiSlice";
import {Auth} from "./Auth";
import { ApiSlice } from "./ApiSlice";

export const store=configureStore({
    reducer:{
        'RobotsApiSlice': RobotsApiSlice.reducer,
        'Products': Products.reducer,
        'Auth':Auth.reducer,
        'PiecesRechange': PiecesRechangeApiSlice.reducer,
        'ApiSlice':ApiSlice.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            RobotsApiSlice.middleware,
            PiecesRechangeApiSlice.middleware,
            ApiSlice.middleware
        ),
    })
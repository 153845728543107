import React,{useEffect} from 'react'
import Nav from '../components/navebar/adminNave/Nav';
import Sidebarr from '../components/navebar/adminNave/Sidebarr';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function AdminLayout() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div style={{ backgroundColor: '#f8f8fb', minHeight: '100vh' }}>
            <Nav />
            <Sidebarr />
            <Outlet />
        </div>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import './index.css'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Departement({ setShowDepartement, robots }) {
    const navigate = useNavigate()
    const [activeList, setActiveList] = useState(null);
    const PiecesRechange = useSelector(state => state.Products.piecesRechange)
    const Products = Array.isArray(robots) ? [...robots] : [];
    // const ProduictsPiecesRechange = Array.isArray(PiecesRechange) ? [...PiecesRechange] : [];


    return (
        <div className='departement_content'>
            <div className='container-fluid container-lg'>
                <div className='row'>
                    <div className='col-4 col-xl-3 mt-4 '>
                        <ul style={{ height: '100%' }} className='departement_content_list p-0 '>
                            <li onMouseEnter={() => setActiveList(null)}
                                onClick={() => {
                                    navigate('/')
                                    setShowDepartement(false)
                                }}
                            >
                                <h3 >Home</h3>
                                <p >Retour à la page principale.</p>
                            </li>
                            <li
                                onMouseEnter={() => setActiveList('robot')}
                                className={activeList === 'robot' ? 'onhover' : ''}
                                onClick={() => {
                                    navigate('/Robots')
                                    setShowDepartement(false)
                                }}
                            >
                                <h3 >Nos Robots</h3>
                                <p >Explorez notre moulin à épices électrique pour une mouture parfaite.</p>
                            </li>
                            <li
                                onMouseEnter={() => setActiveList('Pieces')}
                                className={activeList === 'Pieces' ? 'onhover' : ''}
                                onClick={() => {
                                    navigate('/Pieces-de-rechange')
                                    setShowDepartement(false)
                                }}
                            >
                                <h3 >Pieces de rechange</h3>
                                <p >Trouvez les pièces nécessaires pour maintenir vos équipements.</p>
                            </li>
                            <li onMouseEnter={() => setActiveList(null)}
                                onClick={() => {
                                    navigate('/guide-utilisation')
                                    setShowDepartement(false)
                                }}
                            >
                                <h3 >Guide d’utilisation</h3>
                                <p >Accédez aux manuels et guides pour nos produits</p>
                            </li>
                            <li onMouseEnter={() => setActiveList(null)}
                                onClick={() => {
                                    navigate('/Blogs')
                                    setShowDepartement(false)
                                }}
                            >
                                <h3 >Blogs</h3>
                                <p >{`Lisez les dernières nouvelles et articles de l'industrie.`}</p>
                            </li>
                            <li
                            onClick={() => {
                                navigate('/contact-us')
                                setShowDepartement(false)
                            }}
                            >
                                <h3 >Contactez-nous</h3>
                                < >
                                    <p>{`Posez vos questions ou demandez de l'aide`}</p>
                                    <EmailOutlinedIcon sx={{ fontSize: 25, marginRight: '10px' }} />
                                    <CallOutlinedIcon sx={{ fontSize: 25, marginRight: '10px' }} />
                                    <ContactSupportOutlinedIcon sx={{ fontSize: 20 }} />
                                </>
                            </li>
                        </ul>
                    </div>
                    <div className='col-4 mt-4 col-xl-5'>
                        <div className='section_articles'>
                            <ul className='px-0'>
                                {activeList === 'robot' ?
                                    Products.map(product => (
                                        product.variants.map(variant => (
                                            <li key={variant.id} className='pb-2 px-0'>
                                                <Link
                                                    className='d-flex align-items-center'
                                                    onClick={() => setShowDepartement(false)}
                                                    to={`/Product/${product.slug}/${variant.security === 1 ? 'security' : ''}`}
                                                >
                                                    <img
                                                        style={{ width: '30px', aspectRatio: 1 }}
                                                        src={variant.images && variant.images.length > 0 ? variant.images[0].image_url : 'default-image-url'}
                                                        alt="produit robot"
                                                    />
                                                    {product.name + ' ' + variant.Type}
                                                </Link>
                                            </li>
                                        ))
                                    ))
                                    : activeList === 'Pieces' ?
                                        PiecesRechange.map(piece => (
                                            <li key={piece.id} className='pb-2 px-0'>
                                                <Link
                                                    className='d-flex align-items-center'
                                                    onClick={() => setShowDepartement(false)}
                                                    to={`/Product/Pieces-rechange/${piece.slug}`}
                                                >
                                                    <img
                                                        style={{ width: '30px', aspectRatio: 1 }}
                                                        src={piece.types && piece.types[0] && piece.types[0].images && piece.types[0].images.length > 0 ? piece.types[0].images[0].image_url : 'default-image-url'}
                                                        alt="piece rechange"
                                                    />
                                                    {piece.name}
                                                </Link>
                                            </li>
                                        )) : ''
                                }
                            </ul>
                        </div>
                        <div className='section_articles_pieces'></div>
                    </div>

                    <div className='col-4 about_us_col my-3'>
                        <div className='d-flex justify-content-between'>
                            <div style={{ backgroundColor: 'red', width: '48%', height: '200px', borderRadius: '12px' }}></div>
                            <div className='d-flex flex-column justify-content-between' style={{ width: '48%', height: '200px', borderRadius: '12px' }}>
                                <div style={{ backgroundColor: 'green', width: '100%', height: '48%', borderRadius: '12px' }}></div>
                                <div style={{ backgroundColor: 'yellow', width: '100%', height: '48%', borderRadius: '12px' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



// @ts-nocheck
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkAdminValidityToken } from '../postApi/Axios';
import CircularIndeterminate from '../components/loading/Loading';
import { logout_Admin } from '../store/Auth';

export default function AdminPrivateRoute({ children }) {
    const adminToken = useSelector(state=>state.Auth.adminToken)
    const dispatch = useDispatch();
    const [isValidToken, setIsValidToken] = useState(null);

    useEffect(() => {
        const checkToken = async () => {
            if (adminToken) {
                try {
                    const response = await checkAdminValidityToken(adminToken);
                    if (response.status === 200) {
                        setIsValidToken(true);
                    } else {
                        setIsValidToken(false);
                        dispatch(logout_Admin());
                    }
                } catch (error) {
                    console.error('An error occurred while checking token validity:', error);
                    setIsValidToken(false);
                    dispatch(logout_Admin());
                }
            } else {
                setIsValidToken(false);
            }
        };
        checkToken();
    }, [adminToken, dispatch]);

    if (isValidToken === null) {
        // Render loading spinner or nothing while token validation is in progress
        return (
            <div >
                <CircularIndeterminate />
            </div>
        );
    }

    if (!isValidToken) {
        // Redirect to the login page
        return <Navigate to="/admin-login" />;
    }

    return children;
}

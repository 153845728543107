import React from 'react'
import './index.css'

export default function ConditionVent() {
    return (
        <div className='ConditionVent'>
            <div className='container-fluid container-xl'>
                <div className='section1 my-5'>
                    <h2 className='section_title text-start my-4'>Préambule</h2>
                    <p className='paragraph'>
                        Les présentes Conditions Générales de vente Damay (ci-après les « CGV ») s’appliquent à toute commande de Produits effectuée par un Acheteur auprès sur le site Damay (wordpress-1251499-4489056.cloudwaysapps.com). Les CGV applicables sont celles en vigueur au jour de la passation de la commande par l’Acheteur. La passation de toute commande via le site Damay ou vis notre magasin est subordonnée à l’acceptation entière et sans réserve des présentes Conditions Générales de Vente. Les conditions générales de vente suivantes régissent l’ensemble des transactions établies sur le site web Damay. Toute commande passée sur ce site suppose du client son acceptation inconditionnelle et irrévocable de ces conditions.
                    </p>
                </div>
                <div className='line'></div>

                <div className='section2 my-5'>
                    <h2 className='section_title text-start my-4'>Produit</h2>
                    <p className='paragraph'>
                        Sur notre site web, nous proposons une gamme variée de produits de qualité. Chaque produit est soigneusement décrit dans sa fiche individuelle, où vous trouverez des informations détaillées sur ses caractéristiques, son prix, et sa disponibilité. Nous nous efforçons de maintenir ces informations à jour pour vous offrir une expérience de shopping en ligne transparente.
                    </p>
                </div>
                <div className='line'></div>

                <div className='section3 my-5'>
                    <h2 className='section_title text-start my-4'>Prix</h2>
                    <p className='paragraph'>
                        Le prix de chaque produit est clairement indiqué dans sa fiche produit respective. Nous faisons de notre mieux pour vous proposer des prix compétitifs tout en maintenant la qualité de nos produits. Veuillez noter que les prix peuvent être sujets à des modifications, mais une fois que vous avez passé une commande, le prix convenu sera respecté. Le prix de chaque produit est hors frais de livraison, le prix final affiché et détaillé au moment de la validation de commande par l’acheteur représente le total du prix du produit commandé et les frais de livraison fixé selon votre ville.
                    </p>
                </div>
                <div className='line'></div>

                <div className='section4 my-5'>
                    <h2 className='section_title text-start my-4'>Commande</h2>
                    <h3 style={{ color: 'black', fontSize: '600' }}>Comment passer une commande sur le site web ?</h3>
                    <h4 style={{ color: 'black', fontSize: '600' }}>Pour procéder au paiement sur notre site web, veuillez suivre les étapes suivantes :</h4>
                    <ol>
                        <li>1. Connectez-vous à votre compte client sur notre site web.</li>
                        <li>2. Sélectionnez le produit que vous souhaitez acheter et ajoutez-le à votre panier.</li>
                        <li>3. Accédez à votre panier et vérifiez que les détails de votre commande sont corrects.</li>
                        <li>4. Appuyez sur je confirme ma commande et vous allez recevoir une notification que votre commande a été bien enregistrée.</li>
                    </ol>

                    <h3 style={{ color: 'black', fontSize: '600' }}>Est-il possible d’annuler une commande ?</h3>
                    <p className='paragraph'>Vous avez le droit d’annuler votre commande à tout moment avant son expédition. Cependant, une fois que votre commande a été expédiée, elle ne peut plus être annulée, mais elle peut être retournée conformément à notre politique de retour.
                        Comment annuler une commande</p>

                    <ol>
                        <li>. Connectez-vous à votre compte sur notre site web, si vous en avez un.</li>
                        <li>. Accédez à la section “Historique des commandes” ou “Commandes en cours” de votre compte</li>
                        <li>. Copier le numéro de commande que vous souhaitez annulée</li>
                        <li>.  Contacter le centre d’assistance Damay pour le communiquer votre numéro pour confirmer avec vous l’annulation avant une éventuelle expédition de la dite commande.</li>
                    </ol>

                    <p className='paragraph'>Si vous n’avez pas de compte sur notre site, vous pouvez également contacter notre service client par téléphone ou par e-mail pour demander l’annulation de votre commande.
                        Assurez-vous d’inclure les informations de votre commande, la date de commande, l’article commandé ainsi que vos informations personnelles utilisées au moment de passation de votre commande dans votre demande d’annulation.</p>
                </div>
                <div className='line'></div>

                <div className='section5 my-5'>
                    <h2 className='section_title text-start my-4'>Paiement​</h2>
                    <p className='paragraph'>Nous comprenons que chaque client a des préférences différentes en matière de paiement. C’est pourquoi nous vous offrons la flexibilité de choisir entre deux méthodes de paiement :</p>
                    <h4 className='my-4'>1. Paiement en Ligne :</h4>
                    <p className='paragraph'>Vous pouvez effectuer le paiement en ligne en utilisant l’une des méthodes de paiement sécurisées que nous acceptons. Lorsque vous passez à la caisse, vous serez redirigé vers un environnement de paiement sécurisé où vous pourrez fournir vos informations de paiement en toute confiance.</p>
                    <h4 className='my-4'>2. Paiement à la Livraison :</h4>
                    <p className='paragraph'>
                        Si vous préférez ne pas effectuer de paiement en ligne, vous avez la possibilité de choisir le paiement à la livraison.
                        <br />
                        <br />
                        Quel que soit le mode de paiement que vous choisissez, nous veillons à ce que vos informations personnelles et financières soient traitées en toute sécurité. Nous utilisons des mesures de sécurité strictes pour protéger vos données et garantir une expérience de paiement en ligne fiable.
                        <br />
                        <br />
                        Si vous avez des questions concernant les produits, les prix, ou les méthodes de paiement, n’hésitez pas à nous contacter. Notre équipe est là pour vous aider à faire le meilleur choix pour vos besoins. Nous sommes ravis de vous servir et de vous offrir une expérience de shopping en ligne agréable et pratique.
                        <br />
                        <br />
                        Veuillez noter que les conditions de paiement à la livraison peuvent varier en fonction de votre emplacement géographique, alors assurez-vous de vérifier les détails lors de la commande.
                    </p>

                </div>
                <div className='line'></div>

                <div className='section6 my-5'>
                    <h2 className='section_title text-start my-4'>Livraison</h2>
                    <p className='paragraph'>
                        Nous sommes fiers de vous offrir des politiques de livraison flexibles et pratiques pour répondre à vos besoins. Nous travaillons avec des partenaires de livraison de confiance pour garantir que vos articles arrivent à temps et en parfait état.</p>
                    <h3 className='my-4'>Quels sont les délais de livraison ?</h3>
                    <h4 className='my-4'>Délai de livraison :</h4>
                    <p className='paragraph'>Les délais de livraison peuvent varier entre 1 à 2 jours ouvrables.</p>

                    <h3 className='my-4'>Quel est le coût de la livraison ?​</h3>
                    <p className='paragraph'>Le coût de livraison peut varier en fonction de plusieurs facteurs tels que le lieu de livraison, le poids du colis et la rapidité de la livraison. Chez notre entreprise, nous proposons différents modes de livraison pour répondre aux besoins de nos clients. Les frais de livraison sont calculés automatiquement en fonction des informations fournies lors de la commande. Nous nous efforçons de proposer des tarifs compétitifs tout en garantissant la qualité de notre service de livraison.</p>

                    <h3 className='my-4'>Comment gérer une commande réacheminée ?​</h3>
                    <p className='paragraph'>Notre société de livraison vous contactera pour organiser la livraison à l’adresse spécifiée lors de la passation de la commande.En cas d’injoignabilité, le livreur vous recontactera. Si après 3 tentatives, vous êtes toujours injoignable, la commande est réacheminée vers nos entrepôts et annulée par la suite.</p>

                </div>
                <div className='line'></div>

                <div className='section7 my-5'>
                    <h2 className='section_title text-start my-4'>Rétractation</h2>
                    <p className='paragraph'>
                        Chez Damay, nous souhaitons que vous soyez entièrement satisfait de votre achat. Si, pour une raison quelconque, vous n’êtes pas satisfait de votre commande, vous avez le droit de vous rétracter conformément aux lois et réglementations en vigueur.
                        Veuillez lire attentivement les informations ci-dessous pour comprendre votre droit de rétraction et comment procéder
                        Conformément aux dispositions légales en vigueur, le Client dispose d’un délai de rétractation et de sept (7) jours francs à compter du jour de réception de la commande.
                        <br />
                        <br />
                        L’Acheteur consommateur peut retourner son produit, sans avoir à justifier de motifs ni à payer de pénalités, à l’exception des frais de retour qui restent à sa charge, tout article, dans un état permettant sa remise en vente (ceci supposant qu’il soit retourné dans son emballage d’origine)
                        <br />
                        <br />
                        Si le client a déjà utiliser le produit il faut prévoir des frais supplémentaires de nettoyage du produit.
                        <br />
                        <br />
                        Afin de demander le remboursement pour retourner un ou plusieurs Produits, l’Acheteur consommateur doit prendre contact avec le centre de relation client Damay sur le Service Client ou en ligne sur le Site pour demander un retour et remboursement. Le processus de retour et de remboursement sont développés dans les rubriques « politique de retour et remboursement et échange ».
                    </p>

                </div>
                <div className='line'></div>

                <div className='section8 my-5'>
                    <h2 className='section_title text-start my-4'>Garantie</h2>
                    <p className='paragraph'>
                        Chez DAMAY, nous nous engageons à offrir à nos clients des produits de qualité supérieure. Nous comprenons également que parfois, les produits peuvent rencontrer des pannes ou peuvent ne pas répondre aux attentes de nos clients. C’est pourquoi nous offrons une garantie robuste pour la satisfaction de nos clients.</p>

                    <h3 className='my-4'>Quel est la durée de la garantie?</h3>
                    <p className='paragraph'>Nous offrons une garantie limitée de 6 mois sur le robot électrique « DAMAY ». Si vous rencontrez un problème avec votre produit dans les 6 mois suivant la date d’achat, nous le réparerons gratuitement ou sinon nous fournirons un produit de remplacement</p>
                    <h3 className='my-4'>Quelles sont les pièces couvertes par la garantie?</h3>
                    <ol>
                        <li>. La garantie couvre toutes les pièces du produit, cela signifie que si une pièce se brise ou ne fonctionne pas correctement, elle sera remplacée gratuitement.</li>
                        <li>. La garantie couvre la réparation nécessaire d’un produit défectueux. Cela signifie que si le produit nécessite une réparation, le travail sera effectué gratuitement.</li>
                    </ol>
                    <h3 className='my-4'>Quelles sont les pièces non couvertes par la garantie?</h3>
                    <ol>
                        <li>. La garantie ne couvre pas les dommages causés par une mauvaise utilisation, une utilisation abusive ou une négligence. Elle ne couvre pas non plus les dommages causés par une installation incorrecte ou par des réparations effectuées par des personnes non autorisées.</li>
                        <li>. La garantie couvre seulement et uniquement tout défaut de fabrication. Nous nous engageons au cas où <u>un défaut de fabrication serait reconnu par nos techniciens</u>  sur une pièce ou un élément quelconque de la machine, de procéder à sa réparation ou à son remplacement.</li>
                    </ol>
                </div>
                <div className='line'></div>

                <div className='section9 my-5'>
                    <h2 className='section_title text-start my-4'>SAV/ Service Après Vente​</h2>
                    <p className='paragraph'>
                        Notre politique de service après-vente vise à fournir une assistance de qualité à nos clients pour résoudre les problèmes liés à aux produits. Nous proposons une assistance à distance, en vous fournissant des vidéos et des instructions détaillées pour la réparation de votre produit, ainsi que la possibilité d’acheter des pièces de rechange. Voici les détails de notre politique :</p>
                    <h3>Comment bénéficier du service après-vente?</h3>
                    <p className='paragraph'>
                        Nous mettons à votre disposition une assistance à distance pour vous guider dans la résolution des problèmes techniques ou des pannes de vos produits. Vous pouvez nous contacter par les moyens suivants
                    </p>

                    <ol>
                        <li>Par e-mail : contact@damay-co.com</li>
                        <li>Par téléphone : +212 766-074-939</li>
                        <li>Par WhatsApp : <a href="https://wa.me/+212766074939"> cliquez ici</a></li>
                    </ol>

                    <p className='paragraph'>Expliquez-nous en détail le problème que vous rencontrez, et nous vous fournirons les instructions nécessaires pour la réparation.</p>
                    <h3 className='my-4'>Comment s’informer à propos de la réparation produit?</h3>
                    <p className='paragraph'>Pour faciliter la réparation de votre produit, nous mettons à votre disposition des vidéos détaillées et des instructions en suivant les étapes mentionnées. Ces ressources vous guideront à travers le processus de réparation, en vous montrant les étapes clés et les précautions à prendre. Vous pouvez accéder à ces vidéos et instructions de réparation sur notre site web, dans la section dédiée au service après-vente (nos services). Si vous ne trouvez pas les informations nécessaires, n’hésitez pas à nous contacter, et nous vous fournirons les instructions appropriées.</p>
                    <h3 className='my-4'>Ou trouver les pièces de rechange du produit?</h3>
                    <p className='paragraph'>Si votre produit nécessite le remplacement de certaines pièces d’usures, nous vous offrons la possibilité d’acheter les pièces de rechange nécessaires. Contactez notre service après-vente en fournissant les détails spécifiques des pièces requises, et nous vous indiquerons la disponibilité et les modalités d’achat. Veuillez noter que les pièces de rechange peuvent être soumises à des frais supplémentaires. Les frais de livraison des pièces de rechange seront également à votre charge.</p>
                    <h3 className='my-4'>Quels sont les limitations et les exclusions du SAV?</h3>
                    <p className='paragraph'>
                        <u>Limitations et exclusions :</u>
                        Il est important de noter que notre assistance à distance et nos ressources de réparation sont conçues pour des problèmes courants et des réparations simples. Dans certains cas plus complexes ou nécessitant des compétences techniques avancées, nous pourrions vous recommander de faire appel à un professionnel partenaire qualifié.
                        De plus, veuillez noter que notre assistance à distance et nos ressources de réparation ne couvrent pas les dommages causés par une mauvaise utilisation, une négligence, des modifications non autorisées ou des accidents. Dans ces cas, des frais supplémentaires peuvent s’appliquer si nous devons intervenir pour résoudre le problème ou fournir des pièces de rechange.
                        Nous nous réservons le droit de refuser l’assistance à distance si le produit est en fin de production ou n’existant plus en stock. Nous nous réservons également le droit de vous communiquer la non disponibilité de la pièce de rechange en stock en cas de rupture de stock, de fin de production ou de commercialisation de la pièce.
                        <u>Confidentialité des données :</u>
                        Dans le cadre de notre assistance à distance, il est possible que nous ayons besoin d’accéder à des informations techniques spécifiques à votre produit. Nous nous engageons à respecter votre vie privée et à traiter toutes les informations que vous partagez avec nous de manière confidentielle. Nous ne divulguerons pas vos données personnelles ou les détails de votre produit à des tiers sans votre consentement préalable, sauf si cela est nécessaire pour effectuer la réparation ou le remplacement du produit.
                        Nous vous recommandons également de prendre des mesures appropriées pour protéger vos données personnelles et de sauvegarder vos informations importantes avant toute intervention sur votre produit.

                    </p>

                    <h3 className='my-4'>Quelle est la responsabilité du client bénéficiant du SAV?</h3>
                    <p className='paragraph'>Pour bénéficier pleinement de notre assistance à distance, des vidéos et des instructions de réparation, nous vous demandons de respecter les responsabilités suivantes :</p>

                    <ol>
                        <li>. Suivez attentivement les instructions fournies dans les vidéos et les instructions de réparation.</li>
                        <li>. Prenez les précautions nécessaires pour assurer votre sécurité et éviter tout dommage supplémentaire lors de la réparation.</li>
                        <li>. Si vous n’êtes pas sûr de pouvoir effectuer la réparation vous-même, nous vous recommandons de faire appel à un professionnel partenaire qualifié.</li>
                        <li>. Informez-nous immédiatement si vous rencontrez des difficultés ou si vous avez des questions supplémentaires pendant le processus de réparation.</li>
                        <li>. Veuillez noter que toute réparation effectuée par vous-même ou par un tiers non autorisé peut entraîner l’annulation de la garantie du produit.</li>
                    </ol>
                    <p className='paragraph'>Nous nous efforçons de fournir une assistance à distance de haute qualité et des ressources de réparation complètes pour vous aider à résoudre les problèmes liés à vos produits. Si vous avez des questions supplémentaires concernant notre politique de service après-vente, n’hésitez pas à nous contacter. Notre équipe sera heureuse de vous assister et de répondre à toutes vos préoccupations.</p>
                </div>
                <div className='line'></div>

                <div className='section10 my-5'>
                    <h2 className='section_title text-start my-4'>Poilitique de retour, rembouresement et échange</h2>
                    <p className='paragraph'>Chez «&nbsp;<strong>DAMAY&nbsp;»</strong> nous nous efforçons à fournir des produits de haute qualité et de garantir la satisfaction de nos clients. Toutefois, nous tenons à souligner que nous n’acceptons pas les retours, les remboursements et les échanges que dans deux cas spécifiques, énumérés ci-dessous :</p>
                    <h3 className='my-4'>Quels sont les conditions de retours et remboursements?</h3>
                    <h4 className='my-4'>– 1. Non ouverture du produit :</h4>
                    <p className='paragraph'>Les retours, les remboursements et les échanges seront acceptés uniquement si le produit est retourné dans son emballage d’origine scellé et n’a pas été ouvert.
                        <br />
                        <br />
                        Veuillez noter que nous ne pourrons pas accepter les retours, les remboursements et les échanges si le produit a été utilisé, endommagé ou altéré de quelque manière que ce soit.
                    </p>
                    <h3 className='my-4'>-2. Défaut de fabrication reconnu par nos techniciens :</h3>
                    <p className='paragraph'>Les retours, les remboursements et les échanges seront autorisés si un défaut de fabrication est identifié par nos techniciens qualifiés.
                        Pour bénéficier de cette option, veuillez nous contacter dans un délai de Sept jours après réception du produit et fournir une description détaillée du défaut.
                        Nous pourrions vous demander de retourner le produit pour une évaluation approfondie par nos techniciens afin de confirmer le défaut de fabrication.
                        <br />
                        <br />
                        Veuillez noter les points suivants concernant notre politique de retour, remboursement et échange :</p>

                    <ol>
                        <li>Les demandes de retour, remboursement ou échange doivent être soumises dans les <strong>Sept</strong>&nbsp;jours qui suivent la réception du produit.</li>
                        <li>Tous les articles retournés doivent être accompagnés d’une preuve d’achat valide, telle qu’une facture ou un reçu de paiement.</li>
                        <li>Les frais de retour seront à la charge du client.</li>
                        <li>Les remboursements seront effectués selon le mode de paiement initial utilisé lors de l’achat, et peuvent prendre une durée de traitement spécifique à chaque demande.</li>
                        <li>Les remboursements ne couvriront que le montant payé pour le produit retourné, excluant les frais de livraison initiaux.</li>
                        <li>Nous vous recommandons de lire attentivement les descriptions des produits, les spécifications, les vidéos de démonstration et les avis clients avant de passer votre commande afin de vous assurer que le produit répond à vos attentes.</li>
                        <li>En cas de non-conformité avec cette politique, nous nous réservons le droit de refuser les retours, les remboursements et les échanges.</li>
                    </ol>

                    <p className='paragraph'>Nous apprécions votre compréhension et votre coopération. Si vous avez des questions supplémentaires ou des préoccupations, n’hésitez pas à contacter notre service clientèle. Notre équipe est là pour vous aider.</p>

                    <h4 className='my-4'>-3. Nous contacter :</h4>
                    <p className='paragraph'>Afin de faciliter le processus de retour, remboursement et échange, nous avons mis à votre disposition un service d’assistance clientèle dédié, notre Customer Support. Vous pouvez contacter notre équipe de support par les moyens suivants :</p>
                    <ol>
                        <li>Par e-mail : contact@damay-co.com</li>
                        <li>Par téléphone : +212 766-074-939</li>
                        <li>Par WhatsApp : <a href="https://wa.me/+212766074939"> cliquez ici</a></li>
                    </ol>

                    <p className='paragraph'>Notre équipe du Customer Support est là pour vous guider et répondre à toutes vos questions concernant les retours, les remboursements et les échanges. N’hésitez pas à les contacter pour obtenir de l’aide et des instructions détaillées sur la procédure à suivre.
                        <br />
                        <br />
                        Expliquez-nous en détail votre réclamation, et nous nous efforcerons de fournir l’assistance nécessaires.
                    </p>
                </div>
            </div>
        </div>
    )
}

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import './index.css';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';import SupervisorAccountTwoToneIcon from '@mui/icons-material/SupervisorAccountTwoTone';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Link, useLocation } from 'react-router-dom';

const sidebarItems = [
    { id: 1, label: 'Dashboard', icon: <SpaceDashboardOutlinedIcon />, path: '/admin' },
    { id: 2, label: 'Gestion des Produits', icon: <Inventory2OutlinedIcon />, path: '/admin/Gestion-Produits' },
    { id: 3, label: 'Gestion des commandes', icon: <AssignmentOutlinedIcon />, path: '/admin/Gestion-des-commandes' },
    { id: 4, label: 'Gestion Admins', icon: <SupervisorAccountTwoToneIcon />, path: '/admin/Gestion-Admins' },
    { id: 5, label: 'Subscribers', icon: <SubscriptionsOutlinedIcon />, path: '/admin/subscribers' },
    { id: 6, label: 'Zone de Livraison', icon: <MapOutlinedIcon />, path: '/admin/Gestion-zone-de-Livraison' },
    { id: 7, label: 'Gestion des Coupon', icon: <DiscountOutlinedIcon />, path: '/admin/Gestion-coupon' },
];

export default function Sidebarr() {
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState();

    useEffect(() => {
        const currentPath = location.pathname;
        const currentItem = sidebarItems.find(item => item.path === currentPath)?.id;
        setSelectedItem(currentItem);
    }, [location]);

    return (
        <>
            <div className="offcanvas offcanvas-start bg-dark" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header pb-2">
                    <div className='start_content d-flex align-items-center'>
                        <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                            <CloseOutlinedIcon sx={{ color: "#fff", fontSize: 30 }} />
                        </IconButton >
                        <div>
                            <img src={'/03-PNG/07-Logo DAMAY Rouge VF.png'} style={{ height: '30px' }} alt='logo' />
                        </div>
                    </div>
                </div>
                <div className="offcanvas-body">
                    <div className="list mt-3">
                        <ul className='p-0'>
                        {sidebarItems.map((item) => (
                            <li
                                key={item.id}
                                className={`py-3 my-2 d-flex align-items-end position-relative`}
                                style={{ borderBottom:'1px solid #5b5b5b' }}
                            >
                                <Link className='d-flex align-items-center position-relative' to={item.path}>
                                    <div style={{ color: '#fff', width: '30px' }}>{React.cloneElement(item.icon, { sx: { color: selectedItem === item.id ? '#e63812' : '#fff' } })}</div>
                                    <p className='mb-0' style={{ color: selectedItem === item.id ? '#e63812' : '#fff', fontSize: '20px', lineHeight: '21px',fontWeight:600 }}>{item.label}</p>
                                </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

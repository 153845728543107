import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
    return (
        <div style={{ overflow: 'hidden' }} className='ErrorPage'>
            <div style={{ height: '100vh', width: '100vw' }} className='container-fluid d-flex align-items-center justify-content-center'>
                <div>
                    <div className='text-center'>
                        <img style={{ width: '400px', aspectRatio: 1 }} src={'/error.png'} alt='error' />
                    </div>
                    <div>
                        <h2 className='text-center'>Oh! Page non trouvée</h2>
                    </div>
                    <p style={{ color: '#1B1B1B', fontSize: '22px', fontWeight: 600, textAlign: 'center' }}>Il semble que nous ne trouvions pas ce que vous cherchez. Peut-être qu’une recherche peut vous aider ou revenir à la page d’accueil</p>
                    <div className='text-center'>
                        <Link style={{ fontSize: '20px', fontWeight: "600", color: '#fff', backgroundColor: '#', borderRadius: '8px', padding: '10px' }} to={'/'}>Page accueil</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState } from 'react';
import './index.css'
import { useSelector } from 'react-redux';
import { useGetZonesLivraisonQuery } from '../../../store/ApiSlice';
import { removeZones, removeZone } from '../../../postApi/Axios';
import CircularIndeterminate from '../../../components/loading/Loading';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Dialog, DialogContent } from '@mui/material';
import AddZones from './AddZones';



export default function ManageZone() {
  const adminToken = useSelector(state => state.Auth.adminToken);
  const [loading, setloading] = useState(false)
  const [open, setOpen] = useState(false);
  const { data: zones, isLoading, isError, error, refetch } = useGetZonesLivraisonQuery(adminToken);
  if (isError) return <div>Error: {error.message}</div>;


  const handleDeletezones = async (price) => {
    setloading(true);
    try {
      const response = await removeZones(adminToken, { price: price });
      if (response.status === 200) {
        refetch()
        toast.success('Zone(s) deleted successfully!');
      } else {
        toast.error('Failed to delete the zone. Please try again.');
      }
    } catch (error) {
      toast.error(`Error deleting the zone: ${error.message || 'Unknown error'}`);
    } finally {
      setloading(false);
    }
  };

  // Function to handle update action
  const handleDeletezone = async (id) => {

    setloading(true);
    try {
      const response = await removeZone(adminToken, { id: id });
      if (response.status === 200) {
        refetch()
        toast.success('Zone deleted successfully!');
      } else {
        toast.error('Failed to delete the zone. Please try again.');
      }
    } catch (error) {
      toast.error(`Error deleting the zone: ${error.message || 'Unknown error'}`);
    } finally {
      setloading(false);
    }
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {(loading || isLoading) && <CircularIndeterminate />}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <AddZones closeDialog={handleClose} adminToken={adminToken} setloading={setloading} refetch={refetch} />
        </DialogContent>
      </Dialog>
      <div className='managezone'>
        <div className='container-fluid container-xl'>
          <h2 className='section_title p-4'>Zones d’expédition</h2>
          <div className='my-4'>
            <IconButton onClick={handleClickOpen} className='addzones' sx={{ fontSize: 16, color: '#fff', borderRadius: '5px', backgroundColor: '#000000', fontWeight: 600 }}>Ajouter une zone</IconButton>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="delivery zones table">
              <TableHead style={{ backgroundColor: '#d7d7d7' }}>
                <TableRow>
                  <TableCell style={{ fontSize: '18px', fontWeight: 600 }}>Prix (DH)</TableCell>
                  <TableCell style={{ fontSize: '18px', fontWeight: 600 }}>Villes</TableCell>
                  <TableCell style={{ fontSize: '18px', fontWeight: 600 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zones?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ fontSize: '16px', fontWeight: 600, color: '#7f7f7f' }}>{item.price}</TableCell>
                    <TableCell style={{ fontSize: '16px', fontWeight: 600, color: '#7f7f7f' }}>
                      <div style={{ width: '100%' }}>
                        {item.villes.map(ville => (
                          <p style={{ position: 'relative', backgroundColor: '#77d19b', borderRadius: '5px', color: '#fff' }} className='m-1 d-inline-block p-2'>{ville.name}
                            <CloseOutlinedIcon onClick={() => handleDeletezone(ville.id)} style={{ position: 'absolute', top: "-5px", right: '-5px', color: 'red', fontSize: 22, fontWeight: 700, zIndex: 3, cursor: 'pointer' }} />
                          </p>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      <IconButton className='py-1 removezone' style={{ fontSize: '20px', background: '#ff4d4d', borderRadius: '5px', margin: '3px', width: '100px', color: "#fff" }} onClick={() => handleDeletezones(item.price)} >
                        remove
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

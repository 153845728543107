import React, { useState, useEffect } from 'react';
import './index.css';
import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useOutletContext } from 'react-router-dom';
import { updateuser } from '../../../postApi/Axios';
import CircularIndeterminate from '../../../components/loading/Loading';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function DataPersonnelles() {
    const { userdata, refetch,userToken } = useOutletContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [modificationForm, setModificationForm] = useState(false);
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        email: '',
        tel: ''
    });
    const [errorsvalidation, setErrorsvalidation] = useState({
        nom: '',
        prenom: '',
        email: '',
        tel: ''
    });


    useEffect(() => {
        if (userdata) {
            setFormData({
                nom: userdata.nom || '',
                prenom: userdata.prenom || '',
                email: userdata.email || '',
                tel: userdata.tel || ''
            });
        }
    }, [userdata]);

    const handleToggle = () => {
        setModificationForm(!modificationForm);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrorsvalidation({
            ...errorsvalidation,
            [name]: ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate the form data
        if (!formData.nom || !formData.prenom || !formData.email || !formData.tel) {
            setErrorsvalidation({
                nom: formData.nom ? '' : 'Le nom est requis',
                prenom: formData.prenom ? '' : 'Le prénom est requis',
                email: formData.email ? '' : 'L\'email est requis',
                tel: formData.tel ? '' : 'Le numéro de téléphone est requis',
            });
            return;
        }
        if (formData.tel.length !== 10 || !/^\d+$/.test(formData.tel)) {
            setErrorsvalidation({
                ...errorsvalidation,
                tel: 'Le numéro de téléphone doit être composé de 10 chiffres'
            });
            return;
        }
        try {
            setLoading(true)
            const response = await updateuser(userToken, formData)
            if (response.status === 200) {
                setModificationForm(false);
                setOpen(true)
                //refetch data user and show mgg
                refetch();
            }
        } catch (error) {
            console.error('Error updating user:', error);
        } finally {
            setLoading(false)
        }
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            {loading && <CircularIndeterminate />}
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} >
                <Alert severity="success">Les modifications ont été enregistrées</Alert>
            </Snackbar>
            <div className='DataPersonnelles'>
                <div>
                    {!modificationForm ? (
                        <>
                            <h2 className='section_title text-start'>Données personnelles</h2>
                            <div className='border contentInfo px-0 p-md-4'>
                                <ul className='p-0 p-md-2'>
                                    <li>
                                        <span className='label'>Nom </span>
                                        <span className='value'>{formData.nom}</span>
                                    </li>
                                    <li>
                                        <span className='label'>Prenom </span>
                                        <span className='value'>{formData.prenom}</span>
                                    </li>
                                    <li>
                                        <span className='label'>Email </span>
                                        <span className='value'>{formData.email}</span>
                                    </li>
                                    <li>
                                        <span className='label'>Tel </span>
                                        <span className='value'>{formData.tel}</span>
                                    </li>
                                </ul>
                                <div className='btnmodifier'>
                                    <IconButton className='m-3' onClick={handleToggle}>MODIFIER</IconButton>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 className='section_title text-start mb-4'>Modifier les données personnelles</h2>
                            <div className='modifierInfo px-0 p-md-4 md-80 mb-3 mt-5 my-md-3'>
                                <form className='row g-3 p-3' onSubmit={handleSubmit}>
                                    <TextField
                                        className='my-3'
                                        id="nom"
                                        label="Nom"
                                        variant="outlined"
                                        name="nom"
                                        value={formData.nom}
                                        onChange={handleChange}
                                        error={!!errorsvalidation.nom}
                                        helperText={errorsvalidation.nom}
                                    />
                                    <TextField
                                        className='my-3'
                                        id="prenom"
                                        label="Prénom"
                                        variant="outlined"
                                        name="prenom"
                                        value={formData.prenom}
                                        onChange={handleChange}
                                        error={!!errorsvalidation.prenom}
                                        helperText={errorsvalidation.prenom}
                                    />
                                    <TextField
                                        className='my-3'
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        error={!!errorsvalidation.email}
                                        helperText={errorsvalidation.email}
                                    />
                                    <TextField
                                        className='my-3'
                                        id="tel"
                                        label="Tel"
                                        variant="outlined"
                                        name="tel"
                                        value={formData.tel}
                                        onChange={handleChange}
                                        error={!!errorsvalidation.tel}
                                        helperText={errorsvalidation.tel}
                                    />
                                    <IconButton type='submit'>Enregistrer les modifications</IconButton>
                                </form>

                            </div>
                            <IconButton className='annulermodification p-0' onClick={handleToggle}><KeyboardBackspaceRoundedIcon /> ANNULER</IconButton>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

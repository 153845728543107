import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CoffeeMakerOutlinedIcon from '@mui/icons-material/CoffeeMakerOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Link } from 'react-router-dom';



export default function Sidenav() {

    const sidebarItems = [
        { id: 1, label: 'Home', icon: <HomeOutlinedIcon sx={{ fontSize: 30 }} />, path: '/' },
        { id: 2, label: 'Nos Robots', icon: <CoffeeMakerOutlinedIcon sx={{ fontSize: 30 }} />, path: '/Robots' },
        { id: 3, label: 'Pieces de rechange', icon: <ExtensionOutlinedIcon sx={{ fontSize: 30 }} />, path: '/Pieces-de-rechange' },
        { id: 4, label: 'Guide d’utilisation', icon: <MenuBookOutlinedIcon sx={{ fontSize: 30 }} />, path: '/guide-utilisation' },
        { id: 5, label: 'Blogs', icon: <NewspaperOutlinedIcon sx={{ fontSize: 30 }} />, path: '/Blogs' },
        { id: 6, label: 'Contactez-nous', icon: <CallOutlinedIcon sx={{ fontSize: 30 }} />, path: '/contact-us' },


        // Add more items here with their respective icons and paths
    ];
    return (
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header py-3">
                <div className='start_content d-flex align-items-center'>
                    <Link to={'/'} data-bs-dismiss="offcanvas" aria-label="Close">
                        <img src={'/03-PNG/07-Logo DAMAY Rouge VF.png'} style={{ width: '70%', height: '100%' }} alt='logo' />            
                    </Link>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseOutlinedIcon sx={{ color: "#807272", fontSize: 25 }} />
                    </IconButton >
                </div>
            </div>
            <div className="offcanvas-body">
                <div className="list mt-3">
                    <ul className='p-0'>
                        {sidebarItems.map((item) => (
                            <IconButton  data-bs-dismiss="offcanvas" aria-label="Close" style={{ width: '100%', borderRadius: 0, padding: 0 }} key={item.id}
                                className={`py-2 my-2 d-flex align-items-end justify-content-between position-relative`}>
                                <Link to={item.path} style={{ width: "100%" }} className='d-flex align-items-center justify-content-between position-relative' >
                                    <div className='align-items-center d-flex justify-content-center'>
                                        <div className='label_link'>{item.label}</div>
                                    </div>
                                    <ArrowForwardIosOutlinedIcon sx={{ color:'#2f2f2f' }} />
                                </Link>
                            </IconButton>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';
import { IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, logoutUser } from '../../../../store/Auth';
import { login, logout } from '../../../../postApi/Axios';
import CircularIndeterminate from '../../../loading/Loading';

export default function OnhoverUser() {
    const userToken = useSelector(state => state.Auth.userToken)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [loading, setloading] = useState(false)
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        emailOrTel: '',
        password: '',
        rememberMe: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate form data
        let errors = {};
        if (!formData.emailOrTel) {
            errors.emailOrTel = 'Email or Tel is required';
        } else if (!(/^\S+@\S+\.\S+$/.test(formData.emailOrTel) || /^\d{10}$/.test(formData.emailOrTel))) {
            errors.emailOrTel = 'Invalid email or phone number format';
        }
        if (!formData.password) {
            errors.password = 'Password is required';
        }
        if (Object.keys(errors).length === 0) {
            try {
                setloading(true)
                const response = await login(formData);
                if (response.status === 200) {
                    dispatch(loginUser(response.data))
                    setFormData({
                        emailOrTel: '',
                        password: '',
                        rememberMe: false
                    })
                    navigate('/') // Redirect to the desired page upon successful login
                } else {
                    // Handle login failure (show error message, etc.)
                    throw new Error('Login failed. Please check your credentials.');
                }
            } catch (error) {
                setMessage('login failed. ' + (error.message || 'Please try again.'));
                if (error.errors) {
                    setErrors(error.errors);
                }
            } finally {
                setloading(false)
            }
        } else {
            setErrors(errors);
        }
    };

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            setloading(true);
            const response = await logout(userToken);
            if (response.status === 200) {
                dispatch(logoutUser());
                navigate('/login');
            } else {
                throw new Error('Logout failed. Please try again.');
            }
        } catch (error) {
            setMessage('Logout failed. ' + (error.message || 'Please try again.'));
        } finally {
            setloading(false);
        }
    };

    return (
        <>
            {
                loading && (
                    <CircularIndeterminate />
                )
            }
            <div style={{ width: '300px', background: '#fff' }} className='OnhoverUser'>
                {!userToken ? (
                    <div className='p-3'>
                        <h2 className='section_title py-3'>Login</h2>
                        <form>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="emailOrTel"
                                    value={formData.emailOrTel}
                                    onChange={handleChange}
                                    className={`input-field mb-3 ${errors.emailOrTel ? 'error' : ''}`}
                                    placeholder='Email ou Tel'
                                    autoComplete="off"
                                />
                                {errors.emailOrTel && <div className="error-message">{errors.emailOrTel}</div>}
                            </div>
                            <div className="input-group">
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className={`input-field mb-3 ${errors.password ? 'error' : ''}`}
                                    placeholder='Password'
                                    autoComplete="off"
                                />
                                {errors.password && <div className="error-message">{errors.password}</div>}
                            </div>
                            <div className='remember_me mb-1'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="rememberMe"
                                            checked={formData.rememberMe}
                                            onChange={handleChange}
                                        />
                                    }
                                    label='Remember me'
                                />
                            </div>
                            <div className='loginBtn'>
                                <IconButton onClick={handleSubmit}>Login</IconButton>
                            </div>
                            <div className='OnhoverUserlinks my-2'>
                                <Link className='d-block mb-1' to={'/'}>{`j'ai oublie le mot de passe`}</Link>
                                <Link className='d-block' to={'/register'}>Create an account</Link>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div className='p-3 loggedTrue'>
                        <h2 className='section_title text-start'>Bonjour</h2>
                        <div>
                            <h3>
                                <IconButton component={Link} to={'/user'}>Mon compte</IconButton>
                            </h3>
                            <div className='line my-2'></div>
                            <h3>
                                <IconButton onClick={handleLogout} >Se déconnecter</IconButton>
                            </h3>
                        </div>
                    </div>
                )}
            </div>
        </>

    );
}

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './index.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Subscribe } from '../../postApi/Axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function Footer() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [showForm, setShowForm] = useState(true);

    useEffect(() => {
        // Check if the 'subscribed' cookie is set
        const isSubscribed = Cookies.get('subscribed');
        if (isSubscribed) {
            setShowForm(false);
        }
    }, []);

    const handleSubscribe = async (event) => {
        event.preventDefault(); // Prevent the form from refreshing the page
        try {
            const response = await Subscribe({ name, email });
            if(response.status === 200){
                Cookies.set('subscribed', 'true', { expires: 365 }); // Set a cookie for 30 days
                setShowForm(false)
                toast.success(response.data.message);
                setName('');
                setEmail('');
            }
        } catch (error) {
            toast.error(error.response.data.message || 'Failed to subscribe');
        }
    };
    return (
        <div className='footer mt-5'>
            <footer className="text-center text-lg-start bg-body-tertiary text-muted">
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className="col-10 col-sm-5 col-md-3">
                                <img style={{ width: '100%' }} src={'/03-PNG/05-Logo DAMAY Noir VF edited.png'} alt="logo" />
                            </div>

                            <div className='col-12 col-lg-9 mt-2 mt-lg-0'>
                            {showForm && (
                                <form className='inscrire_email row' onSubmit={handleSubscribe}>
                                    <div className='col-12 col-lg-9'>
                                        <div className='row'>
                                            <div className='col-12 col-lg-6 mt-2 mt-lg-0'>
                                                <input type='text' placeholder='Nom' value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className='col-12 col-lg-6 mt-2 mt-lg-0'>
                                                <input type='email' placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-3 mt-2 mt-lg-0'>
                                        <button type='submit'>{`S'inscrire email`}</button>
                                    </div>
                                </form>
                            )}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer_links">
                    <div className="container-fluid container-lg text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-3">
                                <h4 className="mb-4">
                                    À PROPOS
                                </h4>
                                <p>
                                    {`Damay révolutionne la cuisine avec des moulins à épices électriques et pièces de rechange de haute qualité, conçus pour l'efficacité et la précision.`}
                                </p>
                                <Link style={{ color:'#666',borderBottom:'1px solid #666',fontSize:'20px',paddingBottom:"6px" }} to={'/cgv-damay'}>CGV Damay</Link>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 Suivez_nous ">
                                <h4 className="mb-4">
                                    Suivez-nous
                                </h4>
                                <p>
                                    <a className='d-flex align-items-center justify-content-md-start justify-content-center' target="_blank" href='https://facebook.com/DAMAYMAROC'>
                                        <FacebookIcon sx={{ fontSize: 30, marginRight: "8px" }} />
                                        <span>Facebook</span>
                                    </a>
                                </p>

                                <p>
                                    <a className='d-flex align-items-center justify-content-md-start justify-content-center' target="_blank" href='https://www.instagram.com/damaymaroc/'>
                                        <InstagramIcon sx={{ fontSize: 30, color: '#fc466b', marginRight: "8px" }} />
                                        <span>Instagram</span>
                                    </a>
                                </p>

                                <p>
                                    <a className='d-flex align-items-center justify-content-md-start justify-content-center' target="_blank" href='https://www.youtube.com/@damaymaroc'>
                                        <YouTubeIcon sx={{ fontSize: 30, color: '#b00', marginRight: "8px" }} />
                                        <span>Youtube</span>
                                    </a>
                                </p>

                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h4 className="mb-4">
                                    Nos Marque
                                </h4>
                                <p>
                                    <a href="#!" className="text-reset">Smab</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Kazsen</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">{`O'lie`}</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Drytor</a>
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h4 className="mb-4">Contacter Nous</h4>
                                <p><i className="fas fa-home me-3"></i>21, Bd Lahcen Ouider, Casablanca, Maroc</p>
                                <p><i className="fas fa-phone me-3"></i>+212 766-074-939</p>
                                <p>
                                    <i className="fas fa-envelope me-3"></i>
                                    contact@damay-co.com
                                </p>

                            </div>
                        </div>
                    </div>
                </section>

                <div className="slogan_footer" >
                    <div className='container-fluid py-3'>
                        <img style={{ width: '100%', height: '100%' }} src={'/images/DAMAY-1536x512.png'} alt="logo" />

                        <div className="copyrght_footer" >
                            <div className=" d-md-flex justify-content-between align-items-center mt-3 pt-2" style={{ borderTop: '1px solid #cfcfcf' }}>
                                <p className='m-0'> © 2024 Damay. Tous les droits sont réservés</p>
                                <div className='mode_payments_images'>
                                    <img className='me-2' src={'/images/Untitled-design-2.png'} alt='payment methods' />
                                    <img className='me-2' src={'/images/Untitled-design-3.png'} alt='payment methods' />
                                    <img className='me-2' src={'/images/Untitled-design-4.png'} alt='payment methods' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </footer>
        </div>
    )
}

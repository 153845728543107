import React from 'react'
import './index.css'
import { useSelector } from 'react-redux'

export default function Caracteristique({VariantId}) {
    const products=useSelector(state=>state.Products.robots)
    const variant = products.flatMap(product => product.variants).find(variant => variant.id === VariantId);
    if(!variant){
        return (
            <div className="Product">
                <div className="loading">
                    <p>loading</p>
                </div>
            </div>
        );
    }


  return (
    <div className='CaracteristiqueComponent'>
        <div className='container-fluid'>
        <div className="tableCaracteristique">
                    
                        <div className="row mb-2 " >
                            <div className="p-3 col-5 col-md-4 property" style={{ backgroundColor: '#f2f2f2' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>Puissance</p>
                            </div>
                            <div className="p-3 col-7 col-md-6 value" style={{ backgroundColor: '#f9f9f9' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>{variant.Puissance} W</p>
                            </div>
                        </div>

                        <div className="row mb-2 " >
                            <div className="p-3 col-5 col-md-4 property" style={{ backgroundColor: '#f2f2f2' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>Finesse</p>
                            </div>
                            <div className="p-3 col-7 col-md-6 value" style={{ backgroundColor: '#f9f9f9' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>{variant.Finesse} mesh</p>
                            </div>
                        </div>
                    
                        <div className="row mb-2 " >
                            <div className="p-3 col-5 col-md-4 property" style={{ backgroundColor: '#f2f2f2' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>Vitesse de rotation</p>
                            </div>
                            <div className="p-3 col-7 col-md-6 value" style={{ backgroundColor: '#f9f9f9' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>{variant.Vitesse_de_rotation} Tr/min</p>
                            </div>
                        </div>

                        <div className="row mb-2 " >
                            <div className="p-3 col-5 col-md-4 property" style={{ backgroundColor: '#f2f2f2' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>Poids Brut</p>
                            </div>
                            <div className="p-3 col-7 col-md-6 value" style={{ backgroundColor: '#f9f9f9' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>{variant.Poids_Brut} Kg</p>
                            </div>
                        </div>

                        <div className="row mb-2 " >
                            <div className="p-3 col-5 col-md-4 property" style={{ backgroundColor: '#f2f2f2' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>Poids Net</p>
                            </div>
                            <div className="p-3 col-7 col-md-6 value" style={{ backgroundColor: '#f9f9f9' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>{variant.Poids_Net} Kg</p>
                            </div>
                        </div>
                </div>
        </div>
    </div>
  )
}

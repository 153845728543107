/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Slider from "react-slick";
import './index.css'

export default function Carousel({content}) {

    const src =[
        '/avis1.jpg',
        '/avis2.jpg',
        '/avis3.jpg',
        '/avis4.jpg'
    ]

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,         
        autoplaySpeed: 2000,   
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div className='contentImage'>
                    <img style={{ width: '90%',height:'100%',borderRadius:'8px' }} src={src[0]} alt='screenshot avis client' />
                </div>

                <div className='contentImage'>
                    <img style={{ width: '90%',height:'100%',borderRadius:'8px' }} src={src[1]} alt='screenshot avis client' />
                </div>

                <div className='contentImage'>
                    <img style={{ width: '90%',height:'100%',borderRadius:'8px' }} src={src[2]} alt='screenshot avis client' />
                </div>

                <div className='contentImage'>
                    <img style={{ width: '90%',height:'100%',borderRadius:'8px' }} src={src[3]} alt='screenshot avis client' />
                </div>

            </Slider>
        </div>
    )
}

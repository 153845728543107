import React, { useState } from 'react';
import './index.css';
import { IconButton } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link, useNavigate } from 'react-router-dom';
import { register, fetchCSRFCookie } from '../../postApi/Axios';
import Loading from '../../components/loading/Loading'

export default function Register() {
    const navigate=useNavigate()
    const [formData, setFormData] = useState({
        Prenom: '',
        Nom: '',
        email: '',
        tel: '',
        password: ''
    });

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [loading, setloading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.Prenom = formData.Prenom ? "" : "First Name is required.";
        tempErrors.Nom = formData.Nom ? "" : "Last Name is required.";
        tempErrors.email = /\S+@\S+\.\S+/.test(formData.email) ? "" : "Email is not valid.";
        tempErrors.tel = formData.tel.length > 9 ? "" : "Phone number must be at least 10 digits.";
        tempErrors.password = formData.password.length > 5 ? "" : "Password must be at least 6 characters.";
        setErrors({ ...tempErrors });
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setloading(true);
            try {
                await fetchCSRFCookie();
                const response = await register(formData);
                if(response.status===201){
                    setMessage('User registered successfully!');
                    navigate('/login');  // Redirect to login page after successful registration
                }
            } catch (error) {
                setMessage('échec de la Registration. Veuillez réessayer');
                if (error.errors) {
                    setErrors(error.errors);
                }
            }finally{
                setloading(false)
            }
        }
    };

    return (
        <>
        {loading&&(
            <Loading />
        )}
        
        <div className='RegisterPage my-4'>
            <div className='container-fluid container-xl'>
                <div className='row'>
                    <div style={{ background: "#000000" }} className='col-12 col-xl-4 p-0'>
                        <div className='theSteps'>
                            <ul className='px-0 py-3 '>
                                <li className='d-flex justify-content-start align-items-center p-4'>
                                    <h3 style={{ color: '#fff', fontSize: '27px', fontWeight: 600 }} className='mb-0'>Peronal Infomation</h3>
                                </li>
                                <div style={{ height: '1px', backgroundColor: '#ffffff14' }} className='theStepsline'></div>
                            </ul>
                        </div>
                    </div>
                    <div style={{ background: "#e9e9e9" }} className='col-12 col-xl-8'>
                        <div className='formRegister'>
                            <h2 className="section_title pt-4">Peronal Infomation</h2>
                            <p style={{ color: '#666', fontSize: '24px', fontWeight: 600, width: '90%', margin: 'auto' }} className='text-center'>{`Veuillez saisir vos informations et passer à l'étape suivante afin que nous puissions créer vos comptes.`}</p>
                            <form onSubmit={handleSubmit}>
                                <div className='row' style={{ width: '100%', margin: 'auto' }}>
                                    <div className='col-12 col-xl-6 my-3'>
                                    <input type="text" name="Prenom" value={formData.Prenom} onChange={handleChange} placeholder="First Name" className={`input-field ${errors.Prenom ? 'error' : ''}`} />
                                    {errors.Prenom && <div className="error-message">{errors.Prenom}</div>}
                                    </div>
                                    <div className='col-12 col-xl-6 my-3'>
                                    <input type="text" name="Nom" value={formData.Nom} onChange={handleChange} placeholder="Last Name" className={`input-field ${errors.Nom ? 'error' : ''}`} />
                                    {errors.Nom && <div className="error-message">{errors.Nom}</div>}
                                    </div>

                                    <div className='col-12 my-3'>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" className={`input-field ${errors.email ? 'error' : ''}`} />
                                    {errors.email && <div className="error-message">{errors.email}</div>}
                                    </div>

                                    <div className='col-12 my-3'>
                                    <input type="text" name="tel" value={formData.tel} onChange={handleChange} placeholder="Phone Number" className={`input-field ${errors.tel ? 'error' : ''}`} />
                                    {errors.tel && <div className="error-message">{errors.tel}</div>}
                                    </div>

                                    <div className='col-12 my-4'>
                                    <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" className={`input-field ${errors.password ? 'error' : ''}`} />
                                    {errors.password && <div className="error-message">{errors.password}</div>}
                                    </div>

                                    {message && (
                                        <div className='col-12 my-4'>
                                            <p style={{ color: message.includes('successfully') ? 'green' : 'red' }}>{message}</p>
                                        </div>
                                    )}

                                    <div className='d-flex align-items-center justify-content-between mb-3'>
                                        <IconButton style={{ color:'#000000' }} component={Link} to={'/login'}>{`J'ai un compte `}</IconButton>
                                        <span className='registerbutton d-flex align-items-end'>
                                            <IconButton type='submit'>{`s'inscrire`}</IconButton>
                                            <ArrowRightAltIcon sx={{ font:40,color:'#000000' }}/>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

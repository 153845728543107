/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from 'react';
import './index.css';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Link, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector, useDispatch } from 'react-redux';
import { selectCartSubtotal, increaseQuantity, removeFromCart, decreaseQuantity, removeAllFromCart } from '../../store/Products';
import CircularIndeterminate from '../../components/loading/Loading';
import { useGetZoneLivraisonQuery } from '../../store/ApiSlice';
import FormControl from '@mui/material/FormControl';
import { storeOrder, applypromo } from '../../postApi/Axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { toast } from 'react-toastify';


export default function Pannier() {
    const { data: zones, isLoading: loadingZone } = useGetZoneLivraisonQuery();
    const navigate = useNavigate();
    const userToken = useSelector(state => state.Auth.userToken)
    const articles = useSelector(state => state.Products.cart);
    const cartSubtotal = useSelector(selectCartSubtotal);
    const [promoCode, setPromoCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [Livraison, setLivraison] = useState({ id: '', zone: '', price: 0 });
    const [orderData, setOrderData] = useState({ articles: [], subtotal: 0, delivery: {}, total: 0, clientInfo: {} });
    const dispatch = useDispatch();
    const [selectedMethod, setSelectedMethod] = useState('');
    const [clientInfo, setClientInfo] = useState({ prenom: '', nom: '', adresse: '', tel: '', email: '' });
    const [responseMsg, setresponseMsg] = useState({ success: '', error: '' })
    const [isSuccess, setIsSuccess] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [newTotal, setnewTotal] = useState('');
    const [totalDiscount, settotalDiscount] = useState('');


    const handleInputChange = (event) => {
        setPromoCode(event.target.value); // Update state with input value
    };

    const handleApplyClick = async () => {
        if (!articles.length < 1) {
            try {
                setLoading(true);  // Start loading indicator
                const response = await applypromo(promoCode, articles);  // Assuming applypromo is your API call function
                if (response.status === 200) {
                    setnewTotal(response.data.newTotal)
                    settotalDiscount(response.data.totalDiscount)
                    toast.success(response.data.message || 'Promo code applied successfully!');
                } else {
                    toast.error("Le code promo que vous avez saisi n'est pas valide");
                }
            } catch (error) {
                toast.error("Le code promo que vous avez saisi n'est pas valide");
            } finally {
                setLoading(false);
            }
        }
    };


    const handlePaymentMethodChange = (event) => {
        setSelectedMethod(event.target.id);
    };

    useEffect(() => {
        // Update the order data whenever relevant state changes
        setOrderData({
            articles,
            subtotal: newTotal ? newTotal : cartSubtotal,
            delivery: Livraison,
            total: (newTotal ? newTotal : cartSubtotal) + Livraison.price,
            clientInfo,
            methodPayment: selectedMethod,
        });
    }, [articles, cartSubtotal, Livraison, clientInfo, selectedMethod]);


    const handleChange = (event) => {
        const selectedVille = event.target.value;
        const selectedZone = zones.zone.find(item => item.zone === selectedVille);
        setLivraison(selectedZone || { id: '', zone: '', price: 0 });
    };

    const validateInputs = () => {
        const nameAndAddressRegex = /^[a-zA-Z0-9\s]+$/;
        return nameAndAddressRegex.test(clientInfo.prenom) &&
            nameAndAddressRegex.test(clientInfo.nom) &&
            nameAndAddressRegex.test(clientInfo.adresse);
    };

    const handleCheckout = async (e) => {
        e.preventDefault(e);
        if (!validateInputs()) {
            toast.error("Entrée non valide : seuls les lettres, les chiffres et les espaces sont autorisés pour le nom et l'adresse.");
            return;
        }
        try {
            setLoading(true)
            const response = await storeOrder(userToken, orderData)
            if (response.status === 201) {
                dispatch(removeAllFromCart())
                setresponseMsg('Commande créée avec succès');
                setIsSuccess(true);
                setOpenDialog(true);
                // navigate('/');
            } else if (response.status === 200) {
                initiateCmiPayment(response.data);
            }
        } catch {
            setresponseMsg('Échec de la création de la commande, réessayez');
            setIsSuccess(false);
            setOpenDialog(true);
        } finally {
            setLoading(false)
        }
    };

    const initiateCmiPayment = (paymentData) => {
        const form = document.createElement('form');
        form.id = 'payment-data-form';  // Optional: add an id for styling or future reference
        form.method = 'POST';  // Set the form methoda
        form.action = 'https://payment.cmi.co.ma/fim/est3Dgate';  // Set the form action to your CMI payment URL
        form.style.display = 'none';  // Hide the form

        const addHiddenInputs = (data, parentElement) => {
            Object.keys(data).forEach(key => {
                if (typeof data[key] === 'object' && data[key] !== null) {
                    addHiddenInputs(data[key], parentElement);  // Recursively add hidden inputs for nested objects
                } else {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = data[key];
                    parentElement.appendChild(input);
                }
            });
        };
        addHiddenInputs(paymentData, form);
        document.body.appendChild(form);
        form.submit();  // Automatically submit the form
    };



    const handleClientInfoChange = (event) => {
        const { id, value } = event.target;
        setClientInfo(prevInfo => ({ ...prevInfo, [id]: value }));
    };

    const isClientInfoComplete = () => {
        return clientInfo.prenom && clientInfo.nom && clientInfo.adresse && clientInfo.tel && clientInfo.email;
    };

    if (loadingZone) {
        return <CircularIndeterminate />;
    }

    return (
        <>
            {loading && (
                <CircularIndeterminate />
            )}

            {openDialog && (
                <Dialog
                    open={openDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={() => setOpenDialog(false)}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {isSuccess ? (
                                <>
                                    <img style={{ maxWidth: '400px', aspectRatio: 1 }} src={'images/20943859.jpg'} alt='success order' />
                                    <h3 style={{ color: '#83cf83' }}> {responseMsg}</h3>
                                    <div className='d-flex justify-content-end'>
                                        <IconButton onClick={() => navigate('/')}>
                                            close
                                        </IconButton>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <img style={{ maxWidth: '400px', aspectRatio: 1 }} src={'images/error_image.jpg'} alt='error order' />
                                    <h2 style={{ color: 'red' }}> {responseMsg}</h2>
                                </>
                            )}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            )}


            <div className='PannierPage'>
                <div className='container-fluid container-xxl'>
                    <Link to={'/Robots'} className='d-flex align-items-center backToStore py-4'>
                        <ArrowBackIcon />
                        <h4 className='mb-0'>Retour à la boutique </h4>
                    </Link>
                    <div className='row '>
                        <div className='col-12 col-lg-8 col-xl-9'>
                            <div className='PannierContnet'>
                                {articles.map((item, index) => (
                                    <div key={`${item.id}-${index}`} className='PannierContnetBody d-flex align-items-start mb-2 py-3'>
                                        <div className="card mb-3 ">
                                            <div className="d-flex g-0 ">
                                                <img src={item.imageUrl} alt="product" className="img-fluid rounded-start" style={{ width: '150px', height: '150px' }}
                                                />

                                                <div className="pannier_text">
                                                    <div className="card-body p-2">
                                                        <h5 className="name_article_pannier">{item.name}</h5>
                                                        <div className='prix d-flex justify-content-between'>
                                                            <p className='m-0'>{item.price + ' ' + 'Dhs'}</p>
                                                        </div>
                                                        <div className='d-flex qtt_remove justify-content-start align-items-center mt-3'>
                                                            <div className='me-3 Increase_decrease_quantity d-flex align-items-center'>
                                                                <button onClick={() => { dispatch(decreaseQuantity({ id: item.id, category: item.category })) }} className='Increase_decrease'><RemoveIcon sx={{ fontSize: 30 }} /></button>
                                                                <span className='quantity mx-3'>{item.quantity}</span>
                                                                <button onClick={() => { dispatch(increaseQuantity({ id: item.id, category: item.category })) }} className='Increase_decrease'><AddIcon sx={{ fontSize: 30 }} /></button>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => { dispatch(removeFromCart({ id: item.id, category: item.category })) }} className='remove_pannier_page'>
                                                                    <DeleteOutlineIcon style={{ fontSize: 30, color: '#fff' }} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='formCheckout'>
                                <div className='p-4 my-3 formCheckoutTitle'>
                                    <h2 className='m-0'>Informations client</h2>
                                </div>
                                <form>
                                    <div className='p-3 formCheckoutinputs'>
                                        <div className='row'>
                                            <div className='col-12 col-md-6 my-2'>
                                                <input id="prenom" placeholder='Prenom' value={clientInfo.prenom} onChange={handleClientInfoChange} required />
                                            </div>
                                            <div className='col-12 col-md-6 my-2'>
                                                <input id="nom" placeholder='Nom' value={clientInfo.nom} onChange={handleClientInfoChange} required />
                                            </div>
                                            <div className='my-2'>
                                                <input id="adresse" placeholder='Adresse' value={clientInfo.adresse} onChange={handleClientInfoChange} required />
                                            </div>
                                            <div className='my-2'>
                                                <input id="tel" placeholder='Tel' value={clientInfo.tel} onChange={handleClientInfoChange} required />
                                            </div>
                                            <div className='my-2'>
                                                <input id="email" placeholder='email' value={clientInfo.email} onChange={handleClientInfoChange} required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="methodPayment p-3">
                                        <ul className="p-0">
                                            <li className="p-1">
                                                <div>
                                                    <input
                                                        type="radio"
                                                        name="paymentMethod"
                                                        id="cashOnDelivery"
                                                        onChange={handlePaymentMethodChange}
                                                    />
                                                    <label htmlFor="cashOnDelivery">Paiement à la livraison</label>
                                                </div>
                                            </li>
                                            <li className="p-1">
                                                <div>
                                                    <input
                                                        type="radio"
                                                        name="paymentMethod"
                                                        id="bankTransfer"
                                                        onChange={handlePaymentMethodChange}
                                                    />
                                                    <label htmlFor="bankTransfer">Virement bancaire</label>
                                                </div>
                                                {selectedMethod === 'bankTransfer' && (
                                                    <div className="RIB p-4 bg-light">
                                                        <p className="m-0">RIB : 0000011000041910</p>
                                                        <p className="m-0">Bank : Attijariwafa Bank</p>
                                                    </div>
                                                )}
                                            </li>
                                            <li className="p-1">
                                                <div>
                                                    <input
                                                        type="radio"
                                                        name="paymentMethod"
                                                        id="cmi"
                                                        onChange={handlePaymentMethodChange}
                                                    />
                                                    <label htmlFor="cmi">
                                                        <span className="me-2">CMI</span>
                                                        <img src="images/Untitled-design-2.png" alt="CMI logo 1" />
                                                        <img src="images/Untitled-design-3.png" alt="CMI logo 2" />
                                                        <img src="images/Untitled-design-4.png" alt="CMI logo 3" />
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4 col-xl-3 p-0'>
                            <div className='ResumeCommande p-3 py-5'>
                                <h3>Résumé de la commande</h3>
                                <div className='ResumeCommandeContent'>
                                    <div className='d-flex justify-content-between Resumeheader py-3'>
                                        <p>SOUS-TOTAL </p>
                                        <p>{cartSubtotal} Dhs</p>
                                    </div>
                                    <div className='livraison py-3'>
                                        <p>Zone de Livraison</p>
                                        <FormControl variant="filled" sx={{ minWidth: '90%', maxWidth: '300px' }}>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={Livraison.zone || ''}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value=''>Choisir une ville</MenuItem>
                                                {zones.zone.map(item => (
                                                    <MenuItem key={item.id} value={item.zone}>
                                                        {item.zone}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='codePromo py-3'>
                                        <p>Code promo</p>
                                        <div>
                                            <input type='text' placeholder='entrez votre code'
                                                value={promoCode}
                                                onChange={handleInputChange} />
                                        </div>
                                        <IconButton onClick={handleApplyClick} className='my-3 px-4'>Appliquer</IconButton>
                                    </div>
                                    <div className='line'></div>
                                    <div className='total d-flex justify-content-between align-items-center pt-3'>
                                        <p className='mb-0'>Livraison</p>
                                        <p className='mb-0'>{Livraison.price} Dhs</p>
                                    </div>

                                    {totalDiscount && (
                                        <div className='total d-flex justify-content-between align-items-center pt-3'>
                                            <p className='mb-0'>code promo</p>
                                            <p className='mb-0'>-{totalDiscount} Dhs</p>
                                        </div>
                                    )}

                                    <div className='total d-flex justify-content-between align-items-center py-3'>
                                        <p>TOTAL </p>
                                        <p>{(newTotal ? newTotal : cartSubtotal) + Livraison.price} Dhs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='passercommande col-12 col-lg-8 col-xl-9 my-3'>
                            <button
                                disabled={!Livraison.zone || !isClientInfoComplete() || !selectedMethod || !orderData.articles.length}  // Disable if no livraison selected or client info is incomplete or method payment no selected
                                onClick={handleCheckout}>
                                commander
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useState } from 'react';
import './index.css';
import { addCoupon } from '../../../postApi/Axios';
import { Dialog, DialogContent, FormControlLabel, Checkbox, InputLabel, MenuItem, FormControl, Select, Button } from '@mui/material';
import { toast } from 'react-toastify';
import CircularIndeterminate from '../../../components/loading/Loading';


export default function AddCoupon({ admintoken,open, handleClose,refetch }) {
    const [loading,setloading]=useState(false)
    const [code, setCode] = useState('');
    const [discountType, setDiscountType] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [active, setActive] = useState(true);

    const handleChange = (event) => {
        setDiscountType(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!code.trim()) {
            toast.error('Please enter a valid coupon code.');
            return;
        }
        if (!discountType) {
            toast.error('Please select a discount type.');
            return;
        }
        if (discountAmount <= 0) {
            toast.error('Please enter a valid discount amount greater than zero.');
            return;
        }
        const couponData = {
            code,
            discount_type: discountType,
            discount_amount: discountAmount,
            active
        };
        
        try {
            setloading(true)
            const response = await addCoupon(admintoken, couponData);
            if(response.status === 201){
                refetch()
                toast.success('Coupon created successfully!');
                handleClose();
            }
        } catch (error) {
            console.error('Error creating coupon:', error);
            toast.error('Error creating coupon: ' + error.message);
        }finally{
            setloading(false)
        }
    };
    
    if(loading){
        return  <CircularIndeterminate />
    }

    return (
        <>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent>
                <form className='p-3' onSubmit={handleSubmit}>
                    <div className="form-group my-3">
                        <label htmlFor="code">Code</label>
                        <input
                            id="code"
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group my-3">
                        <FormControl fullWidth>
                            <InputLabel id="discount-type-label">Type de réduction</InputLabel>
                            <Select
                                labelId="discount-type-label"
                                id="discount-type-select"
                                value={discountType}
                                onChange={handleChange}
                                label="Type de réduction"
                            >
                                <MenuItem value={'percentage'}>Pourcentage</MenuItem>
                                <MenuItem value={'fixed'}>Fixe</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="form-group my-3">
                        <label htmlFor="discountAmount">Montant de réduction</label>
                        <input
                            id="discountAmount"
                            type="number"
                            value={discountAmount}
                            onChange={(e) => setDiscountAmount(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group my-3">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={active}
                                    onChange={(e) => setActive(e.target.checked)}
                                />
                            }
                            label="Actif"
                        />
                    </div>
                    <div className="form-group">
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button type="submit">Créer</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
        </>
    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userToken: localStorage.getItem('userToken') || '',
  adminToken: localStorage.getItem('adminToken') || '',
}

export const Auth = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.userToken = action.payload.token;
      // Save and token data to local storage
      localStorage.setItem('userToken', action.payload.token);
    },

    loginAdmin: (state, action) => {
      state.adminToken = action.payload.token;
      // Save and token data to local storage
      localStorage.setItem('adminToken', action.payload.token);
    },

    logoutUser: (state) => {
      state.userToken = '';
      // Remove user and token data from local storage
      localStorage.removeItem('userToken');
    },
    logout_Admin: (state) => {
      state.adminToken = '';
      // Remove user and token data from local storage
      localStorage.removeItem('adminToken');
    },
  },
});

export const { loginUser, logoutUser,loginAdmin,logout_Admin } = Auth.actions;
export default Auth.reducer;

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem } from '@mui/material';

function AddProductToCoupon({ open, handleClose, products, onAddProduct }) {
    const [selectedProduct, setSelectedProduct] = useState('');
    const handleChange = (event) => {
        setSelectedProduct(event.target.value);
    };

    const handleAdd = () => {
        onAddProduct(selectedProduct);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Product to Coupon</DialogTitle>
            <DialogContent>
                <Select
                    value={selectedProduct}
                    onChange={handleChange}
                    fullWidth
                >
                {(products.data).map(product => (
                    product.variants.map(variant => (
                        <MenuItem key={variant.id} value={variant.id}>
                            {product.name} - {variant.Type}
                        </MenuItem>
                    ))
                ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd} color="primary">Add</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddProductToCoupon;

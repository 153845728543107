import { useLocation, Link } from 'react-router-dom';
import { removeAllFromCart } from '../store/Products';


function PaymentFailure() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get('message');
    const error = queryParams.get('error');
    return (
        <div style={{ height: '100vh' }} className='d-flex justify-content-center align-items-center'>
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <h1>Paiement échoué</h1>
                <p>{`Nous avons rencontré un problème avec votre paiement. Aucune accusation n'a été portée. Veuillez réessayer ou contacter l'assistance si le problème persiste.`}</p>
                <Link to="/panier" style={{ textDecoration: 'none', color: 'red' }}>Essayer à nouveau</Link>
            </div>
        </div>
    );
}

export default PaymentFailure




import React from 'react'
import './index.css'
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { useGetOverViewAdminQuery, useGetMonthlyRevenuesQuery, useGetOrdersCountByZoneQuery } from '../../../store/ApiSlice';
import { useSelector } from 'react-redux';
import CircularIndeterminate from '../../../components/loading/Loading';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';

const monthNamesFR = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
];


export default function Dashboard() {
  const adminToken = useSelector(state => state.Auth.adminToken)
  const { data: overView, isLoading: loadingoverview } = useGetOverViewAdminQuery(adminToken)
  const { data: revenuesData, isLoading: revenuesloading } = useGetMonthlyRevenuesQuery(adminToken)
  const { data: dataventville, isLoading: dataventvilleloading } = useGetOrdersCountByZoneQuery(adminToken)

  if (revenuesloading || dataventvilleloading) {
    return <CircularIndeterminate />
  }

  // Initialize the revenue data array with zero values for each month
  const processedData = Array.from({ length: 12 }, (_, index) => ({
    month: index + 1,
    revenue: 0
  }));

  // Fill in the actual data
  revenuesData?.forEach(item => {
    processedData[item.month - 1].revenue = item.revenue;
  });

  const revenueChartData = processedData.map(data => data.revenue);
  const revenueChartLabels = monthNamesFR;

  const ventville = Array.isArray(dataventville) && dataventville.length > 0 ? dataventville.map(data => data.order_count) : [];
  const ville = Array.isArray(dataventville) && dataventville.length > 0 ? dataventville.map(data => data.zone) : [];


  return (
    <>
      {loadingoverview && <CircularIndeterminate />}
      <div className='adminDashboard my-3'>
        <div className='container-fluid container-lg'>
          <h2 className='section_title my-3 text-start'>Bienvenue</h2>
          <div className='general_overview'>
            <div className='row'>
              <div className='col-12 col-md-6 p-0'>
                <div className="card m-2">
                  <div className="card-body">
                    <div className='stats-tile d-flex '>
                      <div className='sale-icon'>
                        <SellOutlinedIcon sx={{ color: '#fff', fontSize: 35 }} />
                      </div>
                      <div className='sale-details'>
                        <h3 className='text-red mb-0'>{overView?.totalOrders}</h3>
                        <p className='mb-0'>Total de commandes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-6 p-0'>
                <div className="card m-2">
                  <div className="card-body">
                    <div className='stats-tile d-flex '>
                      <div className='sale-icon'>
                        <PendingActionsOutlinedIcon sx={{ color: '#fff', fontSize: 35 }} />
                      </div>
                      <div className='sale-details'>
                        <h3 className='text-red mb-0'>{overView?.unprocessedOrders}</h3>
                        <p className='mb-0'>Commandes non traitées</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-6 p-0'>
                <div className="card m-2">
                  <div className="card-body">
                    <div className='stats-tile d-flex '>
                      <div className='sale-icon'>
                        <PeopleOutlineOutlinedIcon sx={{ color: '#fff', fontSize: 35 }} />
                      </div>
                      <div className='sale-details'>
                        <h3 className='text-red mb-0'>{overView?.totalCustomers}</h3>
                        <p className='mb-0'>Nombre total de clients</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-6 p-0'>
                <div className="card m-2">
                  <div className="card-body">
                    <div className='stats-tile d-flex '>
                      <div className='sale-icon'>
                        <MonetizationOnOutlinedIcon sx={{ color: '#fff', fontSize: 35 }} />
                      </div>
                      <div className='sale-details'>
                        <h3 className='text-red mb-0'>{overView?.totalRevenue} DH</h3>
                        <p className='mb-0'>Total des revenus </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Evolution des ventes*/}
          <div className='Evolution_ventes p-3 my-4' style={{ background: '#fff' }}>
            <h2 className='_section_title'>Évolution des ventes</h2>
            <LineChart
              height={300}
              series={[
                { data: revenueChartData, label: 'revenus', color: '#ff0000' },
              ]}
              xAxis={[{ scaleType: 'point', data: revenueChartLabels }]}
            />
          </div>
          {/*end Evolution des ventes*/}
          <div className='Best_sellersVente_par_ville' style={{ background: '#fff' }}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='Vente_par_ville' style={{ background: '#fff' }}>
                {ville.length > 0 && ventville.length > 0 ? (
                  <BarChart
                    height={300}
                    series={[
                      { data: ventville, label: 'Vente', id: 'Vente_id', stack: 'total', color: '#e63812' },
                    ]}
                    xAxis={[{ data: ville, scaleType: 'band' }]}
                  />
                ) : (
                  <div>No data available</div>
                )}
                </div>
              </div>
            </div>
          </div>
          {/*end Best sellers & Vente par ville*/}
        </div>
      </div>
    </>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './index.css'
import { useParams, Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Description from '../../components/componentForProductPage/Description';
import Caracteristique from '../../components/componentForProductPage/Caracteristique';
import FicheTechnique from '../../components/componentForProductPage/FicheTechnique';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../store/Products';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import CircularIndeterminate from '../../components/loading/Loading';
import { useGetfileTechnicalQuery } from '../../store/RobotsApiSlice';


export default function Product() {
    const remiseProducts = {
        "000.000.022": { oldPrice: 650 },
        "000.000.771": { oldPrice: 600 }, 
        "000.000.016": { oldPrice: 750},
        "000.000.024": { oldPrice: 900},
        "000.000.769": { oldPrice: 1000 },
        "000.000.025": { oldPrice: 1100 },
        "000.000.017": { oldPrice: 1100 },
      };
    const { productSlug, securite } = useParams();
    const dispatch = useDispatch();
    const [activeItem_lg, setActiveItem_lg] = useState('Description');
    const [activeItem_sm, setActiveItem_sm] = useState('Description');
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [quantity, setquantity] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [mainImage, setMainimage] = useState()
    const [filename, setfilename] = useState('')

    // for related products
    const allProduct = useSelector(state => state.Products.robots)
    const { data: fileTechnical, isLoading: isloadingfile } = useGetfileTechnicalQuery()

    useEffect(() => {
        if (Array.isArray(fileTechnical) && selectedVariant?.id) {
            const file = fileTechnical.find(file => file.variant_id === selectedVariant.id);
            if (file) {
                setfilename(file.technical_specification);
            } else {
                setfilename('');
            }
        }
    }, [selectedVariant, fileTechnical]);


    const flattenedProducts = allProduct.flatMap(product =>
        product.variants.map(variant => ({
            ...product,
            variant
        }))
    );
    // #######################################
    const product = useSelector(state =>
        state.Products.robots.find(robot => robot.slug === productSlug)
    );


    useEffect(() => {
        if (product) {
            const variant = product.variants.find(v => (securite ? v.security === 1 : v.security === 0));
            setSelectedVariant(variant);
        }
    }, [product, securite]);



    useEffect(() => {
        if (product && selectedVariant) {
            const newImageUrls = selectedVariant.images.map(img => img.image_url);
            setImageUrls(newImageUrls);
            setMainimage(newImageUrls[0])
        } else {
            setImageUrls([]);
        }
    }, [productSlug, selectedVariant, product]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [productSlug, securite])

    const handleAddToCart = () => {
        if (selectedVariant) {
            dispatch(addToCart({
                id: selectedVariant.id,
                name: product.name + ' ' + (securite ? 'avec sécurité' : 'sans sécurité'),
                quantity: quantity,
                imageUrl: selectedVariant.images[0].image_url, // Assuming the first image is the main image
                security: selectedVariant.security,
                price: selectedVariant.price,
                category: 'robots',
                capacity: selectedVariant.capacity
            }));
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
        }
    };


    const incraseQtt = () => {
        setquantity(quantity + 1)
    }

    const dicraseQtt = () => {
        if (quantity > 1) {
            setquantity(quantity - 1)
        }
    }

    const displaySection_lg = (section) => {
        setActiveItem_lg(section);
    };

    const displaySection_sm = (section) => {
        setActiveItem_sm(section);
    };


    if (!product || isloadingfile) {
        return (
            <div className="Product">
                <div className="loading">
                    <CircularIndeterminate />
                </div>
            </div>
        );
    }

    return (
        <>

            {selectedVariant && (
                <HelmetProvider>
                    <Helmet replace>
                        <title>{`${product.name} ${selectedVariant.Type}`}</title>
                        <meta name="description" content={selectedVariant.description} />
                        <meta name="keywords" content={selectedVariant.Keywords} />
                    </Helmet>
                </HelmetProvider>
            )}

            {
                showPopup && (
                    <Dialog
                        open={showPopup}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ fontSize: '18px' }} >
                            Le produit a été ajouté au panier
                        </Alert>
                    </Dialog>
                )
            }
            <div className='Product'>
                <div className='container-fluid container-xxl'>
                    <div className='section_one'>
                        <div className="image-container">
                            <div className='row'>
                                <div className='col-12 col-lg-6 d-flex'>
                                    <div className='d-flex align-items-start gap-3'>
                                        <div className='d-flex flex-column gap-3 rounded'>
                                            {imageUrls.slice(0, 3).map((item, index) => (
                                                <img onMouseEnter={() => setMainimage(item)} key={index} src={item} alt='product' style={{ width: '100px', height: '100px', cursor: 'pointer', border: '1px solid #d7d7d7' }} className='rounded' />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <img src={mainImage} alt='main product' style={{ width: '100%', aspectRatio: 1, maxWidth: '500px' }} />
                                    </div>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <div className='product_description mt-5 mt-lg-0'>
                                        <div className='product_name'>
                                            <h3>{product.name}</h3>
                                        </div>
                                        <div className='product_price mb-3'>
                                        {selectedVariant && remiseProducts[selectedVariant.Ref] &&(
                                            <span className='old-price'>{remiseProducts[selectedVariant.Ref].oldPrice.toFixed(2)} dh</span>
                                        )}
                                            <h3>{selectedVariant ? `${selectedVariant.price} Dhs` : 'Price not available'}</h3>
                                        </div>
                                        {product.variants.length > 1 ?
                                            (<div className='securite my-2'>
                                                <IconButton component={Link} to={`/product/${product.slug}/securite`} className={`btn_securite me-2 ${securite ? 'chosen' : ''}`} >Avec sécurité</IconButton>
                                                <IconButton to={`/product/${product.slug}`} component={Link} className={`btn_securite ${!securite ? 'chosen' : ''}`} >Sans sécurité</IconButton>
                                            </div>) : ''}


                                        <div className='qtt_addCart mt-4 d-flex align-items-center'>
                                            <div className='qtt me-2'>
                                                <button onClick={() => { dicraseQtt() }}>
                                                    <RemoveCircleOutlineIcon style={{ color: '#fff', fontSize: '30px', fontWeight: 600 }} />
                                                </button>
                                                <span style={{ fontSize: '20px', fontWeight: 600, color: 'black', width: '20px', marginLeft: '3px', marginRight: '3px' }}>{quantity}</span>
                                                <button onClick={() => { incraseQtt() }} >
                                                    <AddCircleOutlineIcon style={{ color: '#fff', fontSize: '30px', fontWeight: 600 }} />
                                                </button>
                                            </div>
                                            <div className='addCart'>
                                                <button className='btn_Addcart' onClick={handleAddToCart}>
                                                    Ajouter au panier
                                                </button>
                                            </div>
                                        </div>
                                        <div className='section_tabs mt-4'>
                                            <div className='tabs_desktop d-none d-md-block'>
                                                <ul className='d-flex px-0 py-3 justify-content-start'>
                                                    <li className={activeItem_lg === 'Description' ? 'chosen_tab' : ''}
                                                        onClick={() => displaySection_lg('Description')}
                                                    >Description</li>
                                                    <li className={activeItem_lg === 'Caracteristique' ? 'chosen_tab' : ''}
                                                        onClick={() => displaySection_lg('Caracteristique')}
                                                    >Caractéristique</li>
                                                    <li className={activeItem_lg === 'Fiche_technique' ? 'chosen_tab' : ''}
                                                        onClick={() => displaySection_lg('Fiche_technique')}
                                                    >Fiche technique</li>
                                                </ul>

                                                <div className='tabs_content'>
                                                    <div className={activeItem_lg === 'Description' ? 'd-block' : 'd-none'}>
                                                        <Description variantDescription={selectedVariant && selectedVariant.description} />
                                                    </div>

                                                    <div className={activeItem_lg === 'Caracteristique' ? 'd-block' : 'd-none'}>
                                                        <Caracteristique VariantId={selectedVariant && selectedVariant.id} />
                                                    </div>

                                                    <div className={activeItem_lg === 'Fiche_technique' ? 'd-block' : 'd-none'}>
                                                        <FicheTechnique fileName={filename && filename} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tabs_mobile d-md-none'>
                                                <ul className='px-0'>
                                                    <li
                                                        className="py-3"
                                                        style={{ borderBottom: '1px solid #f7f7f7' }}
                                                        onClick={() => displaySection_sm('Description')}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span style={{ color: '#242323', fontSize: '18px', fontWeight: '600' }}>Description</span>
                                                            <span className={activeItem_sm === 'Description' ? 'd-none' : ''}>
                                                                <ExpandMoreOutlinedIcon />
                                                            </span>
                                                            <span className={activeItem_sm === 'Description' ? '' : 'd-none'}>
                                                                <ExpandLessOutlinedIcon />
                                                            </span>
                                                        </div>
                                                    </li>
                                                    {activeItem_sm === 'Description' && (
                                                        <div className="my-3">
                                                            <Description variantDescription={selectedVariant && selectedVariant.description} />
                                                        </div>
                                                    )}

                                                    <li
                                                        className="py-3 "
                                                        style={{ borderBottom: '1px solid #f7f7f7' }}
                                                        onClick={() => displaySection_sm('Caracteristique')}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span style={{ color: '#242323', fontSize: '18px', fontWeight: '600' }}>Caractéristique</span>
                                                            <span className={activeItem_sm === 'Caracteristique' ? 'd-none' : ''}>
                                                                <ExpandMoreOutlinedIcon />
                                                            </span>
                                                            <span className={activeItem_sm === 'Caracteristique' ? '' : 'd-none'}>
                                                                <ExpandLessOutlinedIcon />
                                                            </span>
                                                        </div>
                                                    </li>
                                                    {activeItem_sm === 'Caracteristique' && (
                                                        <div className="my-3">
                                                            <Caracteristique VariantId={selectedVariant && selectedVariant.id} />
                                                        </div>
                                                    )}

                                                    <li
                                                        className="py-3 "
                                                        style={{ borderBottom: '1px solid #f7f7f7' }}
                                                        onClick={() => displaySection_sm('Fiche_technique')}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span style={{ color: '#242323', fontSize: '18px', fontWeight: '600' }}>Fiche technique</span>
                                                            <span className={activeItem_sm === 'Fiche_technique' ? 'd-none' : ''}>
                                                                <ExpandMoreOutlinedIcon />
                                                            </span>
                                                            <span className={activeItem_sm === 'Fiche_technique' ? '' : 'd-none'}>
                                                                <ExpandLessOutlinedIcon />
                                                            </span>
                                                        </div>
                                                    </li>
                                                    {activeItem_sm === 'Fiche_technique' && (
                                                        <div className="my-3">
                                                            <FicheTechnique fileName={filename && filename} />
                                                        </div>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='relatedProducts my-5'>
                    <div className='p-3 my-4' style={{ backgroundColor: '#e5e5e559' }}>
                        <h2 className='section_title my-4'>Produits connexes</h2>
                    </div>
                    <div className='relatedProductsContent'>
                        <div className='container-fluid container-lg'>
                            <div className='row'>
                                {flattenedProducts.map((item, index) => (
                                    <IconButton component={Link} to={`/Product/${item.slug}/${item.variant.security === 1 ? 'securite' : ''}`} key={index} className='col-12 col-sm-6 col-md-3 my-3'>
                                        <div className='card position-relative'>
                                        {selectedVariant && remiseProducts[selectedVariant.Ref] && (
                                            <div className='remisecard'>
                                              <p className='mb-0'>-20%</p>
                                            </div>
                                           )}
                                            <div className='card-image justify-content-center d-flex'>
                                                <img style={{ width: '85%', aspectRatio: 1 }} src={item.variant.images.length > 0 && item.variant.images[0].image_url} alt='related product' />
                                            </div>
                                            <div className='card-body'>
                                                <div className='productName d-flex justify-content-center'>
                                                    <p className='m-0'>{item.name + ' ' + item.variant.Type}</p>
                                                </div>
                                                <div className='relatedProductPrice'>
                                                {selectedVariant && remiseProducts[selectedVariant.Ref] && (
                                                    <span className="old-price">{selectedVariant && remiseProducts[selectedVariant.Ref].oldPrice.toFixed(2)} dh</span> 
                                                  )}
                                                    <p>{item.variant.price} Dhs</p>
                                                </div>
                                            </div>
                                        </div>
                                    </IconButton>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}





import React, { useState } from 'react';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { adminLogin} from '../../postApi/Axios';
import { loginAdmin } from '../../store/Auth';
import CircularIndeterminate from '../../components/loading/Loading';

export default function LoginAdmin() {
    const adminToken = useSelector(state=>state.Auth.adminToken)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading,setloading]=useState(false)

    if(adminToken){
        return <Navigate to="/admin" />;
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        if (!email) {
            errors.email = 'Email is required';
        } else if (!validateEmail(email)) {
            errors.email = 'Email is invalid';
        }
        if (!password) {
            errors.password = 'Password is required';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
        }
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            try {
                setloading(true)
                const response = await adminLogin({ email, password });
                if (response.status === 200) {
                    // Dispatch action if using Redux
                    dispatch(loginAdmin(response.data))
                    navigate('/admin')

                } else {
                    setErrors({ general: 'Login failed. Please check your credentials.' });
                }
            } catch (error) {
                setErrors({ general: 'An error occurred. Please try again later.' });
            }finally{
                setloading(false)
            }
        }
    };

    return (
        <>
        {
            loading &&(
                <CircularIndeterminate />
            )
        }
        <div style={{ height: '100vh' }} className='admin-form d-flex align-items-center'>
            <div className="container">
                <section id="admin-form-content">
                    <form onSubmit={handleSubmit}>
                        <h1>Login Admin</h1>
                        {errors.general && <p className="error">{errors.general}</p>}
                        <div>
                            <input
                                type="text"
                                placeholder="Email"
                                id="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                className={errors.email ? 'error' : ''}
                            />
                            {errors.email && <p className="error">{errors.email}</p>}
                        </div>
                        <div>
                            <input
                                type="password"
                                placeholder="Password"
                                id="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                className={errors.password ? 'error' : ''}
                            />
                            {errors.password && <p className="error">{errors.password}</p>}
                        </div>
                        <input type="submit" value="Log in" />
                    </form>
                </section>
            </div>
        </div>
        </>
    );
}

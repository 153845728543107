import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './index.css';
import RobotsProducts from './RobotsProducts';
import PiecesRechangeProducts from './PiecesRechangeProducts';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadProductFile } from '../../../postApi/Axios';
import CircularIndeterminate from '../../../components/loading/Loading';
import { useGetRobotsForAdminQuery,useGetPiecesRechangeForAdminQuery } from '../../../store/ApiSlice';

export default function GestionProduits() {
  const adminToken = useSelector(state => state.Auth.adminToken)
  const {data:robots,refetch:refetchRobots}=useGetRobotsForAdminQuery(adminToken)
  const {data:piecesRechange,refetch:refetchPiecesRechange}=useGetPiecesRechangeForAdminQuery(adminToken)


  const [robotProducts, setRobotProducts] = useState([]);
  const [piecesProducts, setPiecesProducts] = useState([]);
  const [loading,setloading]=useState(false);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  useEffect(() => {
    if (robots) {
      const transformedRobots = robots.data.flatMap(robot =>
        robot.variants.map(variant => ({
          id: variant.id,
          name: robot.name,
          Image: variant.images && variant.images.length > 0 ? variant.images[0].image_url : '/default-image.jpg',
          Ref: variant.Ref,
          Type: variant.Type,
          price: variant.price,
          Capacity: variant.capacity,
          qtt: variant.qtt,
          Status: variant.Status,
          Description: variant.description || robot.description,
          Keywords: variant.Keywords,
        }))
      );
      setRobotProducts(transformedRobots);
    } else {
    }

    if (piecesRechange) {
      const transformedPieces = piecesRechange.data.flatMap(piece =>
        piece.types.map(type => ({
          id: type.id,
          name: piece.name,
          Image: type.images && type.images.length > 0 ? type.images[0].image_url : '/default-image.jpg',
          Ref: type.Ref,
          price: type.price,
          Capacity: type.capacity,
          qtt: type.qtt,
          Status: type.status,
          Description: type.description || piece.description,
          Keywords: type.Keywords,
        }))
      );
      setPiecesProducts(transformedPieces);
    } else {
    }

  }, [robots, piecesRechange]);

  const handleRobotsFileChange = async(event) => {
    const file = event.target.files[0];
    try{
      setloading(true)
      const response = await uploadProductFile(adminToken, 'robots', file)
      if(response.status === 201){
        refetchRobots()
      }
    }catch{
    }finally{
      setloading(false)
    }

  };

  const handlePiecesRechangeFileChange = async(event) => {
    const file = event.target.files[0];
    try{
      setloading(true)
      const response = await  uploadProductFile(adminToken, 'piecesRechange', file)
      if(response.status === 201){
        refetchPiecesRechange()
      }
    }catch{
    }finally{
      setloading(false)
    }
  };


  return (
    <>
    {loading && <CircularIndeterminate />}
      <div className='gestion_Produit'>
        <div className='container-fluid'>
          <div className='gestion_commandes_head my-3 p-3' style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
            <div className='d-flex justify-content-center align-items-center'>
              <h2 className='section_title' style={{ fontSize: '28px' }}>Gestion des Produits</h2>
            </div>
          </div>

          <div className='sectionrobots my-4'>
            <div className='py-3 d-flex align-items-center justify-content-between'>
              <h3 className='m-0' style={{ fontSize: '2.2em', fontWeight: 600, color: '#918b8b' }}>Gestion des Robots</h3>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload Robots
                <VisuallyHiddenInput type="file" onChange={handleRobotsFileChange} />
              </Button>
            </div>
            <RobotsProducts products={robotProducts} refetchRobots={refetchRobots} />
          </div>

          <div className='sectionpiecesRechange my-5'>
            <div className='py-3 d-flex align-items-center justify-content-between'>
              <h3 className='m-0' style={{ fontSize: '2.2em', fontWeight: 600, color: '#918b8b' }}>Pieces Rechange</h3>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload Pieces Rechange
                <VisuallyHiddenInput type="file" onChange={handlePiecesRechangeFileChange} />
              </Button>
            </div>
            <PiecesRechangeProducts products={piecesProducts} refetchPiecesRechange={refetchPiecesRechange}   />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';

export default function SmallNavbar({ cartlenght, robots }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const searchRef = useRef(null); // Reference to the search component

  // Function to handle search input changes
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.length > 0) {
      const results = robots.filter(robot => robot.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };

  // Function to clear search
  const handleSearchSelect = () => {
    setSearchTerm('');
    setFilteredResults([]);
  };

  // Effect for handling outside click detection
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        handleSearchSelect();
      }
    };

    // Add event listener when the component is mounted
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef]);

  return (
    <div className="container-fluid" ref={searchRef}>
      <div className='row justify-content-between'>
        <div className='departement col-2 justify-content-center'>
          <IconButton className='' style={{ borderRadius: '0px' }} data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
            <WidgetsIcon sx={{ color: '#fff', fontSize: 25 }} />
          </IconButton>
        </div>
        <div className='logo col-4'>
          <Link to={'/'} className="logo">
            <img src={'/03-PNG/03-Logo DAMAY Blanc VF.png'} alt='logo' style={{ width: '100%', height: '100%' }} />
          </Link>
        </div>
        <div className="shorts col-6 d-flex justify-content-evenly">
          <IconButton className='position-relative' component={Link} to="/panier">
            <ShoppingCartOutlinedIcon sx={{ color: '#fff', fontSize: 30 }} />
            {cartlenght ? <div className='point'></div> : ''}
          </IconButton>
          <IconButton component={Link} to="/Login">
            <PermIdentityOutlinedIcon sx={{ color: '#fff', fontSize: 30 }} />
          </IconButton>
          <IconButton>
            <LanguageOutlinedIcon sx={{ color: '#fff', fontSize: 30 }} />
          </IconButton>
        </div>
      </div>

      <div className='row'>
        <div className='search position-relative col-12 p-0'>
          <input
            type='text'
            placeholder='Search'
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={(event) => event.key === 'Enter' && handleSearchSelect()}
          />
          <IconButton className='searchIcon' onClick={handleSearchSelect}>
            <SearchIcon />
          </IconButton>
          {filteredResults.length > 0 && (
            <div className="search-results">
              {filteredResults.map((item, index) => (
                <div key={index} className="search-item p-3" onClick={handleSearchSelect}>
                  <Link to={`/Product/${item.slug}`}>{item.name}</Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

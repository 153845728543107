import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { addZones } from '../../../postApi/Axios';

export default function AddZones({ closeDialog,adminToken,setloading,refetch }) {
    const [price, setPrice] = useState('');
    const [cities, setCities] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setloading(true)
        const priceNumber = parseFloat(price);
        if (!price || isNaN(priceNumber) || !cities) {
            toast.error("Please ensure all fields are filled correctly and price must be a number.");
            setloading(false);
            return;
        }
        if (!price || !cities) {
            toast.error("Please fill all fields.");
            return;
        }
        const zoneData = {
            price: parseFloat(price),
            cities: cities
        };
        try {
            const response = await addZones(adminToken,zoneData)
            if(response.status === 201){
                toast.success('Zone successfully added!');
                refetch()
                closeDialog();
            }
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }finally{
            setloading(false)
        }
    };

    return (
        <div className='AddZones'>
            <h2 className='section_title my-3' style={{ fontSize: '26px' }}>Ajouter de nouvelles régions</h2>
            <form className='form_add_zone' onSubmit={handleSubmit}>
                <div>
                    <input placeholder='Tarif livraison' value={price} onChange={(e) => setPrice(e.target.value)} />
                </div>

                <div className='my-3'>
                    <textarea placeholder='ville1, ville2, ville3 ...' rows="7" value={cities} onChange={(e) => setCities(e.target.value)}></textarea>
                </div>

                <div className='savezones'>
                    <IconButton type='submit'>save</IconButton>
                </div>
            </form>
        </div>
    );
}

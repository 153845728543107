import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function UsePageViews() {
    const location = useLocation();

    useEffect(() => {
        // Make sure the gtag function is available
        if (window.gtag) {
            window.gtag('config', 'G-JXPV5TX70T', {
                page_path: location.pathname + location.search,
            });
        }
    }, [location]);
}

export default UsePageViews;

import React, { useState } from 'react';
import './index.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddCoupon from './AddCoupon'; // Import the AddcodePromo component
import { useGetCouponsAdminQuery, useGetRobotsForAdminQuery } from '../../../store/ApiSlice';
import { useSelector } from 'react-redux';
import CircularIndeterminate from '../../../components/loading/Loading';
import AddProductToCoupon from './AddProductToCoupon';
import { toast } from 'react-toastify';
import { attachproductCoupon, removeattached, removecoupon } from '../../../postApi/Axios';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import UpdateCoupon from './UpdateCoupon';

export default function ManageCoupon() {
    const [open, setOpen] = useState(false);
    const [selectedcoupon, setselectedcoupon] = useState('')
    const [openupdate, setopenupdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productPopupOpen, setProductPopupOpen] = useState(false);
    const [currentCouponId, setCurrentCouponId] = useState(null);
    const adminToken = useSelector(state => state.Auth.adminToken)
    const { data: coupons, isLoading: couponsLoading, refetch } = useGetCouponsAdminQuery(adminToken)
    const { data: robots, isloading: robotLoading } = useGetRobotsForAdminQuery(adminToken)


    if (couponsLoading || robotLoading || loading) {
        return <CircularIndeterminate />
    }

    const handleProductPopupOpen = (couponId) => {
        setCurrentCouponId(couponId);
        setProductPopupOpen(true);

    };

    const handleProductPopupClose = () => {
        setProductPopupOpen(false);
    };

    const addProductToCoupon = async (productId) => {
        try {
            setLoading(true)
            const response = await attachproductCoupon(adminToken, currentCouponId, productId);
            if (response.status === 201) {
                toast.success('Product successfully added to coupon!');
            }
            refetch();  // Refresh coupon data
            handleProductPopupClose();  // Close the product popup
        } catch (error) {
            toast.error('Failed to add product to coupon!');  // Display error toast
        } finally {
            setLoading(false)
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenUpdate = (coupon) => {
        setselectedcoupon(coupon);
        setopenupdate(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseupdate = () => {
        setopenupdate(false);
    };

    const removeVariantFromCoupon = async (couponId, variantId) => {
        try {
            setLoading(true);
            await removeattached(adminToken, couponId, variantId);
            toast.success('Variant removed from coupon successfully');
            refetch();  // Assuming you are using react-query or similar for fetching data
        } catch (error) {
            toast.error('Failed to remove variant from coupon');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCoupon = async (couponId) => {
        try {
            setLoading(true)
            await removecoupon(adminToken, couponId);
            toast.success('Coupon deleted successfully');
            refetch(); // Assuming you are using react-query or another method to refetch data
        } catch (error) {
            toast.error('Failed to delete coupon');
        } finally {
            setLoading(false)
        }
    };

    return (
        <>
            <div className='ManageCoupon m-2'>
                <div className='container-fluid container-xl'>
                    <div className='p-4'>
                        <h2 className='section_title'>Gestion des code promo</h2>
                    </div>

                    <div className='row'>
                        <button className='createPromocode my-4' onClick={handleClickOpen}>
                            créer un code promo
                        </button>

                        <TableContainer component={Paper}>
                            <Table aria-label="">
                                <TableHead style={{ backgroundColor: '#d7d7d7' }}>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '18px', fontWeight: 600 }}>code</TableCell>
                                        <TableCell style={{ fontSize: '18px', fontWeight: 600 }}>discount_type</TableCell>
                                        <TableCell style={{ fontSize: '18px', fontWeight: 600 }}>discount_amount</TableCell>
                                        <TableCell style={{ fontSize: '18px', fontWeight: 600 }}>Produits</TableCell>
                                        <TableCell style={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {coupons.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell style={{ fontSize: '16px', fontWeight: 600, color: '#7f7f7f' }}>{item.code}</TableCell>
                                            <TableCell style={{ fontSize: '16px', fontWeight: 600, color: '#7f7f7f' }}>{item.discount_type}</TableCell>
                                            <TableCell style={{ fontSize: '16px', fontWeight: 600, color: '#7f7f7f' }}>{item.discount_amount}</TableCell>
                                            <TableCell style={{ fontSize: '16px', fontWeight: 600, color: '#7f7f7f' }}>
                                                {/* Mapping through variants and extracting product names */}
                                                {item.variants.length > 0 ? (
                                                    item.variants.map((variant) => (
                                                        <div className='theproductoncoupon position-relative m-1' key={variant.id}>
                                                            <p className='m-0'>{variant.product.name}</p>
                                                            <ClearOutlinedIcon className='deleteprocoup'
                                                                onClick={() => {
                                                                    removeVariantFromCoupon(item.id, variant.id);
                                                                }}
                                                                sx={{ color: 'red', fontSize: 30, cursor: 'pointer' }}
                                                            />                                                        
                                                        </div>
                                                    ))
                                                ) : 'No products'}
                                            </TableCell>
                                            <TableCell className='' style={{ fontSize: '16px', fontWeight: 600, color: '#7f7f7f' }}>
                                                <span className='d-flex align-items-center justify-content-around'>
                                                    <button className='addp' onClick={() => handleProductPopupOpen(item.id)} style={{ color: '#fff', border: 'none', borderRadius: "5px", padding: "6px", width: "100px", backgroundColor: '#0420d3' }}>add product</button>
                                                    <button className='removec' onClick={() => handleDeleteCoupon(item.id)} style={{ color: '#fff', border: 'none', borderRadius: "5px", padding: "6px", width: "100px", backgroundColor: '#dd2c2c', margin: '0 2px' }}>remove</button>
                                                    <button className='editp' style={{ color: '#fff', border: 'none', borderRadius: "5px", padding: "6px", width: "100px", backgroundColor: '#2bb12b' }} onClick={() => handleClickOpenUpdate(item)}>edit</button>
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <AddCoupon open={open} handleClose={handleClose} admintoken={adminToken} refetch={refetch} />
                <UpdateCoupon open={openupdate} handleClose={handleCloseupdate} admintoken={adminToken} refetch={refetch} coupon={selectedcoupon} />
            </div>
            {
                robots && (
                    <AddProductToCoupon
                        open={productPopupOpen}
                        handleClose={handleProductPopupClose}
                        products={robots} // Assuming this is the list of products
                        onAddProduct={addProductToCoupon}
                    />
                )
            }
        </>
    );
}

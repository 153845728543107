import React from 'react'
import './index.css'


export default function Description({variantDescription}) {


  return (
    <div className='DescriptionComponent'>
        <div className='container-fluid'>
            <div className='text_description'>
                <p>“{variantDescription}”</p>
            </div>
            <div className='video_description'>
                
            </div>
        </div>
    </div>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import { createContext, useContext, useEffect } from 'react';
import { RouterProvider } from "react-router-dom";
import { router } from './routes/Index'
import { useGetRobotsQuery } from './store/RobotsApiSlice';
import { useGetPiecesRechangeQuery } from './store/PiecesRechangeApiSlice'; 
import { Products } from './store/Products';
import { useDispatch,useSelector } from "react-redux";
import { checkValidityToken, fetchCSRFCookie } from './postApi/Axios';
import { logoutUser } from './store/Auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


// Create context
const RefetchContext = createContext(null);

// Custom hook to use the context
export const useRefetch = () => useContext(RefetchContext);


function App() {
  const dispatch=useDispatch();
  const userToken = useSelector(state=>state.Auth.userToken)
  const {data:robots,refetch:refetchRobots }=useGetRobotsQuery(userToken)
  const {data:PiecesRechange,refetch:refetchPiecesRechange}=useGetPiecesRechangeQuery(userToken);


  useEffect(() => {
    AOS.init({
      duration: 1000, // You can change the duration or other options here
    });
  }, []);



  const csrf = async() =>{
    await fetchCSRFCookie()
  }

  const checkTokenValidity = async() =>{
    if (userToken) {
      try {
          const response = await checkValidityToken(userToken);
          if (response.status === 200) {
              return
          } else {
              dispatch(logoutUser());
          }
      } catch (error) {
          console.error('An error occurred while checking token validity:', error);
          dispatch(logoutUser());
      }
  } else {
      return
  }
  }

  useEffect(() => {
    csrf()
    checkTokenValidity()
    if (robots && PiecesRechange) {
      dispatch(Products.actions.robots(robots));
      dispatch(Products.actions.PiecesRechange(PiecesRechange));
    }
  }, [robots,PiecesRechange, dispatch,userToken]);
  
  return (
    <RefetchContext.Provider value={{ refetchRobots, refetchPiecesRechange }}>
      <div className="App">
        <ToastContainer />
        <RouterProvider router={router} />
      </div>
    </RefetchContext.Provider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useGetOrdersQuery } from '../../../store/ApiSlice';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DetailsOrder from './DetailsOrder';
import { useSelector } from 'react-redux';
import CircularIndeterminate from '../../../components/loading/Loading';
import { updateStatus } from '../../../postApi/Axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxWidth: '90%',
};

export default function Gestion_commandes() {
  const adminToken = useSelector(state => state.Auth.adminToken);
  const [open, setOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: fetchedOrders, isLoading: isLoadingOrders,  refetch } = useGetOrdersQuery(adminToken);


  useEffect(() => {
    if (fetchedOrders) {
      setOrders(fetchedOrders);
    }
  }, [fetchedOrders]);


  const handleChange = async (orderId, fieldName, newValue) => {
    try {
      setLoading(true);
      const response = await updateStatus(adminToken, {
        id: orderId,
        field: fieldName,
        value: newValue,
      });
      if (response.status === 200) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? { ...order, [fieldName]: newValue } : order
          )
        );
        refetch();
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };

  const rows = orders.map((order) => ({
    id: order.id,
    Prenom: order.prenom,
    nom: order.nom,
    Tel: order.phone,
    adresse: order.adress,
    ville: order.delivery_zone.zone,
    livraisonprix: order.delivery_zone.price,
    total: `${order.total} Dhs`,
    orderDate: formatDate(order.created_at),
    statusOrder: order.statusOrder,
    methodPayment: order.methodPayment,
    statusPayment: order.statusPayment,
  }));

  const handleDetailsClick = (orderId) => {
    setSelectedOrderId(orderId);
    handleOpen();
  };

  const columns = [
    // { field: 'id', headerName: 'Order Id', width: 300 },
    { field: 'Prenom', headerName: 'Prenom', width: 130 },
    { field: 'nom', headerName: 'Nom', width: 130 },
    { field: 'Tel', headerName: 'Tel', width: 150 },
    { field: 'adresse', headerName: 'Adresse', width: 600 },
    { field: 'ville', headerName: 'ville', width: 200 },
    { field: 'livraisonprix', headerName: 'prix livraison', width: 130 },
    { field: 'total', headerName: 'Total', width: 130 },
    { field: 'orderDate', headerName: 'Date de commande', width: 150 },
    { field: 'methodPayment', headerName: 'method de Payment', width: 150 },
    {
      field: 'statusOrder',
      headerName: 'Statut de la commande',
      width: 180,
      renderCell: (params) => (
        <select
          className='statusSelected'
          value={params.row.statusOrder}
          onChange={(e) => handleChange(params.row.id, 'statusOrder', e.target.value)}
        >
          <option value="pending">Pending</option>
          <option value="confirmed">confirmed</option>
          <option value="Expedie">Expedie</option>
          <option value="refused">refused</option>
        </select>
      ),
    },
    {
      field: 'statusPayment',
      headerName: 'Statut du paiement',
      width: 180,
      renderCell: (params) => {
        return (
          <select
            className='statusSelected'
            value={params.row.statusPayment} 
            onChange={(e) => handleChange(params.row.id, 'statusPayment', e.target.value)}
          >
          <option className='d-none' value={params.row.statusPayment}>{params.row.statusPayment} </option>
            <option value="IN PROCESS">IN PROCESS</option>
            <option value="APPROVED">APPROVED</option>
            <option value="FAILURE">FAILURE</option>
            <option value="REFUSED">REFUSED</option>
          </select>
        );
      },
    },
    {
      field: 'details',
      headerName: 'Details',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleDetailsClick(params.row.id)}
        >
          Details
        </Button>
      ),
    },
  ];

  return (
    <>
      {(isLoadingOrders || loading ) && <CircularIndeterminate />}
      <div className='gestion_commandes'>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            {selectedOrderId && <DetailsOrder order_id={selectedOrderId} adminToken={adminToken} />}
          </Box>
        </Modal>
        <div className='container-fluid'>
          <div className='gestion_commandes_head my-3 p-3' style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
            <div className='d-flex justify-content-between align-items-center'>
              <h2 className='section_title'>Historique des commandes</h2>
            </div>
          </div>
          <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect } from 'react';
import './index.css';

export default function CommandesContent({ articles_by_status, dataorders }) {
  // Filter orders based on status
  const filteredOrders = articles_by_status === 'all' 
    ? dataorders 
    : dataorders?.filter(order => order.statusOrder === articles_by_status);

  useEffect(() => {}, [articles_by_status, dataorders]);

  return (
    <div>
      {filteredOrders?.map((order, orderIndex) => (
        <div key={order.id}>
          {order.items.map((item, itemIndex) => (
            <div key={item.id} style={{ borderBottom: '2px solid #efefef' }} className='articles_command py-3 text-center d-flex align-items-start'>
              <div className='p-0 article_image_command'>
                <img style={{ aspectRatio: 1, width: '150px' }} src={item.imageUrl} alt='product' />
              </div>
              <div className=''>
                <div className='article_name_command'>
                  <p className='mb-2'>{item.productName}</p>
                  <div className=''>
                    <div className='price text-start'>Quantité : {item.quantity} </div>
                    <div className='status text-start'>Status : {order.statusOrder}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

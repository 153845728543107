import React, { useState } from 'react';
import './index.css';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetAdminsQuery } from '../../../store/ApiSlice';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularIndeterminate from '../../../components/loading/Loading';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EditAdmin from './EditAdmin';
import { removeAdmin } from '../../../postApi/Axios';
import { toast } from 'react-toastify';
import AddAdmin from './AddAdmin';




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 18,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function GestionAdmins() {
    const [loading,setloading]=useState(false)
    const [openedit, setOpenedit] = useState(false);
    const [openadd, setOpenadd] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const adminToken = useSelector(state => state.Auth.adminToken);
    const { data: adminsData,refetch:refetchAdmin } = useGetAdminsQuery(adminToken);

    const handleClickOpenedit = (admin) => {
        setSelectedAdmin(admin);
        setOpenedit(true);
    };

    const handleCloseedit = () => {
        setOpenedit(false);
    };


    const handleCloseadd = () => {
        setOpenadd(false);
    };

    const handleClickOpenadd = (admin) => {
        setSelectedAdmin(admin);
        setOpenadd(true);
    };

    const handleRemoveAdmin = async(id)=>{
        setloading(true)
        try {
            const response = await removeAdmin(adminToken,id);
            if(response.status===200){
                refetchAdmin()
                toast.success('Admin removed!');
            }
        } catch (error) {
            toast.error(`Failed to remove admin: ${error.message}`);
        }finally{
            setloading(false)
        }
    }


    return (
        <>
            <Dialog
                open={openedit}
                onClose={handleCloseedit}
            >
                <DialogContent>
                    {selectedAdmin && <EditAdmin admin={selectedAdmin} adminToken={adminToken} refetchAdmin={refetchAdmin} setOpenedit={setOpenedit} />}
                </DialogContent>
            </Dialog>

            <Dialog
                open={openadd}
                onClose={handleCloseadd}
            >
                <DialogContent>
                    <AddAdmin  adminToken={adminToken} refetchAdmin={refetchAdmin} setOpenadd={setOpenadd} />
                </DialogContent>
            </Dialog>


            {loading && <CircularIndeterminate />}
            <div className='GestionAdmins p-3'>
                <div className='container-fluid container-lg'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='nbrAdminsbox d-flex align-items-center justify-content-evenly'>
                            <div>
                                <p className='mb-1'>tous les administrateurs</p>
                                <p className='m-0'>{adminsData ? adminsData.length : 'Loading...'}</p>
                            </div>
                            <div>
                                <img style={{ width: "100px", aspectRatio: 1 }} src={'/03-PNG/admins.jpg'} alt='admins' />
                            </div>
                        </div>
                        <div className='addAdminbox'>
                            <IconButton className='d-flex align-items-center' onClick={() => handleClickOpenadd()} >
                                <p className='m-0'>Ajouter Admin</p>
                                <img style={{ width: "100px", aspectRatio: 1 }} src={'/03-PNG/addadmin.jpg'} alt='add admin' />
                            </IconButton>
                        </div>
                    </div>

                    <div className='tableOfAdmins my-4'>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell >Email</StyledTableCell>
                                        <StyledTableCell >Role</StyledTableCell>
                                        <StyledTableCell >Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {adminsData && adminsData.data.map((admin) => (
                                        <StyledTableRow key={admin.id}>
                                            <StyledTableCell >{admin.name}</StyledTableCell>
                                            <StyledTableCell >{admin.email}</StyledTableCell>
                                            <StyledTableCell >{admin.role}</StyledTableCell>
                                            <StyledTableCell >
                                                <IconButton onClick={() => handleClickOpenedit(admin)}>
                                                    <ModeEditOutlineOutlinedIcon sx={{ fontSize: 35, color: '#4d0091' }} />
                                                </IconButton>
                                                <IconButton onClick={()=>handleRemoveAdmin(admin.id)}>
                                                    <DeleteOutlinedIcon sx={{ fontSize: 35, color: '#000000' }} />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </>
    )
}

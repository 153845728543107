import { useState } from 'react'
import AssignmentIcon from '@mui/icons-material/Assignment';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useGetUserOrderQuery } from '../../../store/ApiSlice';
import CommandesContent from './CommandesContent';
import './index.css'
import { useOutletContext } from 'react-router-dom';
import CircularIndeterminate from '../../../components/loading/Loading';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';





export default function Commandes() {
  const [article_by_status, set_article_by_status] = useState('all')
  const { userdata,refetch,userToken } = useOutletContext();


  const {data:userOrderdata,isLoading:loadingOrder}=useGetUserOrderQuery(userToken)
  if(loadingOrder){
      return(
        <CircularIndeterminate />
      )
  }

  return (
    <>
      <h2 className='section_title text-start mb-4'>Commandes</h2>
      <div className='usercommandesDetails'>
        <div className='status_command_link d-flex justify-content-around align-items-start text-center'>
          <div
            onClick={() => set_article_by_status('all')}
          >
            <span>
              <AssignmentIcon className={`icon_status_command icon_label ${article_by_status === 'all' ? 'statusChosen' : ''}`} />
              <p>Tout</p>
            </span>
          </div>

          <div
            onClick={() => set_article_by_status('pending')}
          >
            <span>
              <HourglassTopIcon className={`icon_status_command icon_label ${article_by_status === 'pending' ? 'statusChosen' : ''}`}  />
              <p>En cours</p>
            </span>
          </div>

          <div
            onClick={() => set_article_by_status('confirmed')}
            className={`icon_label ${article_by_status === 'confirmed' ? 'statusChosen' : ''}`}
          >
            <span>
              <CheckRoundedIcon className={`icon_status_command icon_label ${article_by_status === 'confirmed' ? 'statusChosen' : ''}`} />
              <p>confirmé</p>
            </span>
          </div>

          <div
            onClick={() => set_article_by_status('Expedie')}
            className={`icon_label ${article_by_status === 'Expedie' ? 'statusChosen' : ''}`}
          >
            <span>
              <LocalShippingIcon className={`icon_status_command icon_label ${article_by_status === 'Expedie' ? 'statusChosen' : ''}`} />
              <p>Expédié</p>
            </span>
          </div>
        </div>

        <div className='mes_command_articles p-0 p-md-3 my-3' style={{ backgroundColor: '#fff', width: '100%', boxShadow: '#dddddd 0px 0px 2px 0px' }}>
          <CommandesContent articles_by_status={article_by_status} dataorders={userOrderdata} />
        </div>
      </div>
    </>
  )
}

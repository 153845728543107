import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import { removeProduct, updateProductStatus,updateProductField } from '../../../postApi/Axios';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import CircularIndeterminate from '../../../components/loading/Loading';
import { toast } from 'react-toastify';  // Assuming you are using react-toastify for notifications

import { Dialog, DialogContent } from '@mui/material';
import ManageImages from './ManageImages';


export default function PiecesRechangeProducts({ products,refetchPiecesRechange }) {
  const adminToken = useSelector(state => state.Auth.adminToken);
  const [loading, setloading] = useState(false)
  const [openpr, setOpenpr] = useState(false); // To control the dialog visibility
  const [currentProductId, setCurrentProductId] = useState(null); // To store current product ID


  const handleStatusChange = async (id, currentStatus) => {
    const status = currentStatus === 'Active' ? 'Inactive' : 'Active';
    setloading(true);
    try {
      const response = await updateProductStatus(adminToken, 'PiecesRechange', id, status);
      if (response.status === 200) {
        toast.success('Status updated successfully');
        refetchPiecesRechange(); // Optionally refetch data if needed
      } else {
        toast.error('Failed to update status');
      }
    } catch (error) {
      toast.error('Error updating status');
    } finally {
      setloading(false);
    }
  };


  // Dialog open handler
  const handleOpenDialogpr = (id) => {
    setCurrentProductId(id);
    setOpenpr(true);
  };

  // Dialog close handler
  const handleCloseDialogpr = () => {
    setOpenpr(false);
  };

  const handleRemove = async (id) => {
    setloading(true); // Assume setLoading is a function that updates your component's loading state
    try {
      const response = await removeProduct(adminToken, 'PiecesRechange', id); // Ensuring that 'removeRobots' is imported and correctly set up to call your API
      if (response.status === 200) {
        refetchPiecesRechange()
        toast.success('Product successfully removed'); // Display a success message
      } else {
        toast.error('Failed to remove the product'); // Display an error message
      }
    } catch (error) {
      console.error('Error removing product:', error);
      toast.error('Error removing product'); // Display an error message if an exception occurred during the API call
    } finally {
      setloading(false); // Ensure loading is set to false after the operation is complete
    }
  };

  const handleCommit = async (id, field, value)=> {
    setloading(true);
    try {
      const response = await updateProductField(adminToken, 'PiecesRechange', id, field, value);
      if (response.status === 200) {
        toast.success('Field updated successfully');
        refetchPiecesRechange();
      } else {
        toast.error('Failed to update field');
      }
    } catch (error) {
      toast.error('Error updating field');
    } finally {
      setloading(false);
    }
  };

  const processRowUpdate = (newRow, oldRow) => {
    const updatedField = Object.keys(newRow).find(key => newRow[key] !== oldRow[key]);
    handleCommit(newRow.id, updatedField, newRow[updatedField]);
    return newRow;
  };

  const columns = [
    { field: 'Image', headerName: 'Image', width: 100, renderCell: (params) => <img src={params.value} alt="Product" style={{ width: '100%', aspectRatio: 1, borderRadius: '50%' }} /> },
    { field: 'name', headerName: 'name', width: 280, editable: true },
    { field: 'Ref', headerName: 'Ref', width: 130, editable: true },
    { field: 'price', headerName: 'Price', width: 90, editable: true },
    { field: 'Capacity', headerName: 'Capacity', width: 90, editable: true },
    { field: 'qtt', headerName: 'qtt', width: 90, editable: true },
    {
      field: 'Status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => (
        <Switch
          checked={params.value === 'Active'}
          onChange={() => handleStatusChange(params.id, params.value)}
          color="primary"
        />
      )
    },
    { field: 'Description', headerName: 'Description', width: 280, editable: true },
    { field: 'Keywords', headerName: 'Keywords', sortable: false, width: 200, editable: true },
    {
      field: 'actions',
      headerName: 'Remove',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleRemove(params.id)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </>
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: 'Add images',
      headerName: 'Add images',
      width: 110,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleOpenDialogpr(params.id)} color="primary">
            <AddAPhotoRoundedIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </>
      ),
      sortable: false,
      filterable: false,
    },
  ];


  return (
    <>
      {loading && (<CircularIndeterminate />)}
      <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={(error) => toast.error('Error updating field')}
      rows={products}
      rowHeight={90}
      columns={columns}
      initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }}
      pageSizeOptions={[5, 10]}
      experimentalFeatures={{ newEditingApi: true }}
      sx={{
        '& .MuiDataGrid-cell': {
          fontSize: '16px',
          fontWeight: 'bold',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        }
      }}
    />
    <span className='manageimagespopup'>
        <Dialog open={openpr} onClose={handleCloseDialogpr}>
          <DialogContent  >
            {currentProductId && <ManageImages productId={currentProductId} type={'piecesrechange'} refetchdata={refetchPiecesRechange} />}
          </DialogContent>
        </Dialog>
        </span>
      </div>
    </>
  );
}

import React from 'react';
import IconButton from '@mui/material/IconButton';
import './index.css'


export default function FicheTechnique({ fileName }) {

    const handleOpentab = () => {
        if(fileName){
            window.open(`https://damay-co.com/backend/public/pdfs/${fileName}`, '_blank');
        }
    };

    return (
        <>

            <div className='FicheTechniqueComponent'>
                <div className='container-fluid'>
                    <div className='btnDownload text-center'>
                        <IconButton onClick={handleOpentab}>
                            Télécharger la fiche technique
                        </IconButton>
                    </div>
                </div>
            </div>
        </>
    );
}
